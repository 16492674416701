import {Image} from "@arco-design/web-react";
// import logo from "../assets/logo.png";

type LogoProps = {
  size?: number;
  style?: React.CSSProperties;
};
export const Logo = (props: LogoProps) => {
  return (
    <div style={{paddingTop: 5, paddingLeft: 5}}>
      <img
        width={props.size || 200}
        src={
          "https://images.squarespace-cdn.com/content/v1/55e798a6e4b06629f7c5be2f/6d319b57-de6a-4edf-9f93-c073b2efa3d7/werule+new+logo.png?format=1500w"
        }
        alt="logo"
        style={props.style}
      />
    </div>
  );
};
