import './AdminDashboard.css';
import { NavigationCards } from "./NavigationCards/NavigationCards";
import { AdminBanner } from "./AdminBanner/AdminBanner";

export function AdminDashboard() {
  return (
    <div className="admin-dashboard">
      <NavigationCards/>
      <AdminBanner />
    </div>
  );
}