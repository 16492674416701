import { Button, Dropdown, Menu } from "@arco-design/web-react";
import { IconDown } from "@arco-design/web-react/icon";
import { Role } from "../../models/Person";

import './RoleDropdown.css';

const ROLE_OPTIONS: Role[] = ['mentee', 'mentor'];

type Props = {
  role: Role;
  onRoleChange: (role: Role) => void;
}

export function RoleDropdown({ role, onRoleChange }: Props) {
  const getDropdownList = () => {
    return ROLE_OPTIONS.map((role) => {
      return (
        <Menu.Item className="role-dropdown__option" onClick={() => onRoleChange(role)} key={role}>{role}</Menu.Item>
      );
    });
  }

  return (
    <Dropdown
      droplist={<Menu>{getDropdownList()}</Menu>}
      position="bottom"
      trigger="click"
    >
      <Button type='outline' className="create-user__dropdown-trigger">
        {role} <IconDown fontSize={16} />
      </Button>
    </Dropdown>
  );
}