import React, { useState } from "react";
import { Modal, Button } from "@arco-design/web-react";

import { IconDelete } from "@arco-design/web-react/icon";

interface IDeletePostModalProps {
  postId: number;
  onDeletePost: (postId: number) => void;
}

const DeletePostModal = ({ postId, onDeletePost }: IDeletePostModalProps) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    onDeletePost(postId);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      <Button
        style={{
          padding: 0,
          backgroundColor: "transparent",
        }}
        onClick={showModal}
      >
        <IconDelete />
      </Button>

      <Modal
        title="Delete Post"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Delete"
        cancelText="Cancel"
        className="feed-modal"
      >
        <p>Are you sure you want to delete this post?</p>
      </Modal>
    </div>
  );
};

export default DeletePostModal;
