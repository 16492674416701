import './SuccessScreen.css';
import { ReactComponent as SuccessMarkIcon } from "../../assets/icons/success-mark.svg";
import { Button } from "@arco-design/web-react";

type Props = {
  title: string;
  buttonText: string;
  onButtonClick: () => void;
}

export function SuccessScreen({ title, buttonText, onButtonClick }: Props) {
  return (
    <div className="success-screen">
      <h2 className="success-screen__title">{title}</h2>
      <SuccessMarkIcon />
      <div className="success-screen__button-wrapper">
        <Button
          className="success-screen__button"
          size="large"
          shape={"round"}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}