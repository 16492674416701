import './BookingForm.css';
import { HorizontalScrollableContainer } from "../HorizontalScrollableContainer/HorizontalScrollableContainer";
import classNames from "classnames";
import { useEffect, useState } from "react";
import axios from "axios";
import { endpoints } from "../../api/endpoints";
import { Spin } from "@arco-design/web-react";

type TimeSlot = { date: string, time: number };
type TimeSlotsList = Record<string, TimeSlot[]>;

type Props = {
  userId?: string;
  day: string;
  time: number;
  includeSelectedSlotInList?: boolean;
  onDayChange: (date: string) => void;
  onTimeChange: (time: number) => void;
}

export function BookingForm({ userId, day, time, includeSelectedSlotInList, onDayChange, onTimeChange }: Props) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [availableSlots, setAvailableSlots] = useState<TimeSlotsList>({});
  const [availableDays, setAvailableDays] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);


  const loadBooking = () => {
    axios
      .get(`${endpoints.getAvailableHours}${userId}?timezone=${timezone}`)
      .then((response) => {
        if (!!response.data.slots) {
          let slots = response.data.slots;
          const hasSelectedSlot = slots[day] && slots[day].some((timeSlot: TimeSlot) => timeSlot.time === time);

          if (includeSelectedSlotInList && !hasSelectedSlot) {
            slots = {
              ...slots,
              [day]: [...(slots[day] || []), { date: day, time: time }].sort((slotA, slotB) => slotA.time - slotB.time)
            }
          }

          setAvailableSlots(slots);
          setAvailableDays(Object.keys(slots));
          if (!day || !slots[day]) {
            onDayChange(Object.keys(slots)[0]);
          }

          if (!time) {
            const date = Object.keys(slots)[0];
            const timeSlots = availableSlots[date];
            onTimeChange(timeSlots?.length ? timeSlots[0].time : 0);
          }
        }
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    loadBooking();
  }, []);

  return (
    <div className="booking-form">
      <div className="booking-form__header">Schedule Meeting</div>
      {isLoading && !availableDays.length && (
        <div className="booking-form__slots-loader-wrapper"><Spin size={40}/></div>
      )}
      {!isLoading && !availableDays.length && (
        <div className="booking-form__no-slots">No available slots</div>
      )}
      {!!availableDays.length && (
        <HorizontalScrollableContainer>
          {availableDays.map((availableDay) => {
            const date = new Date(availableDay);
            const month = date.toLocaleString('default', { month: 'short' });
            const dayOfWeek = date.toLocaleString('default', { weekday: 'short' });
            const dayNumber = date.getDate();

            return (
              <button
                key={availableDay}
                className={classNames(
                  'booking-form__slot-card',
                  {
                    'booking-form__slot-card-selected': day === availableDay
                  }
                )}
                onClick={() => onDayChange(availableDay)}
              >
                <div>{month}</div>
                <div className="booking-form__date">{dayNumber}</div>
                <div>{dayOfWeek}</div>
              </button>
            )
          })}
        </HorizontalScrollableContainer>
      )}
      <div className="booking-form__header">Time</div>
      {isLoading && !availableSlots[day]?.length && (
        <div className="booking-form__time-loader-wrapper"><Spin size={40}/></div>
      )}
      {!isLoading && !availableSlots[day]?.length && (
        <div className="booking-form__no-slots">No available slots</div>
      )}
      {!!availableSlots[day]?.length && (
        <HorizontalScrollableContainer>
          {availableSlots[day]?.map((availableTime, index) => {
            const hour = availableTime.time;

            return (
              <button
                key={index}
                className={classNames(
                  'booking-form__time-card',
                  {
                    'booking-form__time-card-selected': time === hour
                  }
                )}
                onClick={() => onTimeChange(hour)}
              >
                {hour > 12 ? hour - 12 : hour}:00 {hour > 12 ? "Pm" : "Am"}
              </button>
            )
          })}
        </HorizontalScrollableContainer>
      )}
    </div>
  );
}