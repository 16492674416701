export const BOROUGH_LIST = [
  "Manhattan",
  "Queens",
  "Brooklyn",
  "Bronx",
  "Staten Island",
  "I don't live in NYC",
]

export const GENDER_LIST = [
  "Female",
  "Male",
  "Non-binary/Third Gender",
  "Prefer not to disclose",
]

export const AGE_LIST = [
  "18-24",
  "25-34",
  "35-44",
  "45-54",
  "55-64",
  "65+",
  "Prefer not to disclose",
]

export const RACE_LIST = [
  "Asian",
  "American Indian or Alaska Native",
  "Black or African American",
  "Native Hawaiian or Pacific Islander",
  "Two or More Races",
  "White or Caucasian",
  "Prefer not to disclose",
]

export const ETHNICITY_LIST = [
  "Hispanic or Latina(o)",
  "Not Hispanic or Latina(o)",
  "Prefer not to disclose",
]

export const EDUCATION_LIST = [
  "High school or equivalent",
  "Technical or occupational certificate",
  "Some college coursework completed",
  "Associate's degree",
  "Bachelor's Degree",
  "Master's Degree",
  "Doctorate",
  "Professional",
  "Other (Explain Below)",
]

export const INCOME_RANGE_LIST = [
  "$0-$30,000",
  "$31,000-$60,000",
  "$61,000-$90,000",
  "$91,000-$120,000",
  "$120,000-149,000",
  "$150,000+",
  "Prefer not to disclose",
]

export const OCCUPATION_STATUS_LIST = [
  "Student",
  "Working for an organization",
  "Entrepreneur (Business Owner/Self-Employed)",
  "Solopreneur (Freelancer/Consultant)",
  "Unemployed",
  "In transition",
  "Full-time parenting",
  "Other unpaid family caregiving",
  "Retired",
  "Prefer not to disclose",
]

export const PROFESSIONAL_EXPERIENCE_LIST = [
  "Intern",
  "Entry Level",
  "Mid-career",
  "Management",
  "Senior/Experienced Professional",
  "Other (please specify)",
]

export const INDUSTRIES_LIST = [
  "Marketing/Advertisement",
  "Business development (Commercial Non-Retail)",
  "Construction",
  "Government",
  "Life Sciences/Healthcare",
  "Social Assistance",
  "Legal",
  "Technology or tech-enabled",
  "Manufacturing/Retail trade",
  "Management of Companies/Enterprises",
  "Personal Services (salon/cleaner/repair)",
  "Transport/Warehousing/Aviation",
  "Sustainability/Green Economy",
  "Food and Beverage",
  "Other",
]

export const HIGH_GROWTH_INDUSTRY_LIST = [
  "Tech",
  "Green Economy",
  "Life Sciences",
]

export const AREA_OF_INTERESTS_LIST = [
  "Job Interview Prep",
  "Changing Industries",
  "Personal Branding",
  "Networking",
  "Moving Into Management",
  "Entrepreneurship",
  "Jobs of the future",
  "Work-Life Balance",
  "Negotiation Skills",
  "Other (please specify)",
]

export const HOW_DID_YOU_HEAR_ABOUT_US_LIST = [
  "Email",
  "Instagram",
  "Twitter",
  "LinkedIn",
  "Facebook",
  "EventBrite",
  "Word of Mouth",
  "Women.NYC website",
  "Other",
]

export const CATEGORY_LIST = [
  "Business Model Innovation",
  "Coaching and Consulting",
  "Consumer Goods and Retail",
  "Contract Negotiation",
  "Customer Acquisition and Retention",
  "DE&I Strategy",
  "Diversity in Leadership",
  "E-commerce and Digital Marketing",
  "Education and Technology",
  "Energy and Cleantech",
  "Fashion and Apparel",
  "Financial Management",
  "Financial Services",
  "Food and Agriculture",
  "Fundraising and Investor Relations",
  "Healthcare and Biotech",
  "Intellectual Property Protection",
  "Leadership Development",
  "Legal and Regulatory Framework",
  "M&A and Partnerships",
  "Market Analysis and Strategy",
  "Mentorship and Sponsorship",
  "Negotiation and Influence",
  "Operations Management",
  "Peer Support and Networking",
  "Personal Branding",
  "Pricing and Revenue Optimization",
  "Public Speaking and Media Relations",
  "Resilience and Overcoming Challenges",
  "Risk Management",
  "Sales and Marketing Strategy",
  "Scaling and Growth",
  "Self-Confidence and Imposter Syndrome",
  "Social Impact and Sustainability",
  "Stress Management and Well-being",
  "Supplier Diversity",
  "Technology and Innovation",
  "Time Management and Productivity",
  "Unconscious Bias",
  "Work-Life Balance"
]