import { Button, Dropdown, Menu } from "@arco-design/web-react";
import { IconLeft, IconMoreVertical } from "@arco-design/web-react/icon";
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import React, { useEffect } from "react";
import {
  GroupChannel as GroupChannelType, Member,
  ReportCategoryInfo
} from "@sendbird/chat/lib/__definition";
import './Conversation.css';
import { headerTextColor } from "../../../../utils/cssVariables";
import { useSendbirdStateContext } from "@sendbird/uikit-react";
import { getUserFromConversation } from "../../utils/getUserFromConversation";
import { ConversationAvatar } from "../ConversationAvatar/ConversationAvatar";
import { ConversationReportDialog } from "../ConversationReportDialog/ConversationReportDialog";
import ChannelSettings from '@sendbird/uikit-react/ChannelSettings';
import { getIfOperator } from "../../utils/getIfOperator";
import { useBreakpoint } from "../../../../utils/hooks/useBreakpoint";

type Props = {
  activeConversation: GroupChannelType | null;
  onCloseConversation: () => void;
}

const OPERATOR_ID = '627668';

export function Conversation({ activeConversation, onCloseConversation }: Props) {
  const { stores: { sdkStore: { sdk }, userStore } } = useSendbirdStateContext();

  const { isMobile } = useBreakpoint('mobile');

  const [respondent, setRespondent] = React.useState<Member | null>(null);
  const [isReportDialogOpened, setIsReportDialogOpened] = React.useState<boolean>(false);
  const [isSettingsOpened, setIsSettingsOpened] = React.useState<boolean>(false);

  const isOperator = activeConversation && getIfOperator(activeConversation, userStore.user);

  useEffect(() => {
    if (activeConversation) {
      const respondent = getUserFromConversation(activeConversation, userStore.user);
      setRespondent(respondent);
    } else {
      setRespondent(null);
    }
  }, [activeConversation])

  useEffect(() => {
    setIsSettingsOpened(false);
  }, [activeConversation])

  const handleBlockUser = () => {
    if (activeConversation) {
      if (respondent) {
        sdk.blockUserWithUserId(respondent?.userId).then();
        sdk.groupChannel.getChannel(activeConversation.url).then((channel: GroupChannelType) => {
          channel.leave().then();
          onCloseConversation();
        });
      }
    }
  }

  const handleInviteModerator = () => {
    if (activeConversation) {
      sdk.groupChannel.getChannel(activeConversation.url).then((channel: GroupChannelType) => {
        channel.inviteWithUserIds([OPERATOR_ID]).then((res) => {
          channel.sendUserMessage({
            message: 'Hello! We need some help here.',
            mentionedUserIds: [OPERATOR_ID]
          })
        });
      });
    }
  }

  const handleReportUser = (message: string, category: ReportCategoryInfo) => {
    if (activeConversation && respondent) {
      sdk.groupChannel.getChannel(activeConversation.url).then((channel: GroupChannelType) => {
        channel.reportUser(respondent, category, message).then();
      });
    }
  }

  const handleOpenReportDialog = () => {
    setIsReportDialogOpened(true);
  }

  const handleShowSettings = () => {
    setIsSettingsOpened(true);
  }

  const userMenuDropList = (
    <Menu>
      <Menu.Item key='1' onClick={handleBlockUser}>Block user</Menu.Item>
      <Menu.Item key='2' onClick={handleOpenReportDialog}>Report user</Menu.Item>
      <Menu.Item key='3' style={{ display: 'none' }} onClick={handleInviteModerator}>Invite moderator</Menu.Item>
    </Menu>
  );

  const operatorMenuDropList = (
    <Menu>
      <Menu.Item key='4' onClick={handleShowSettings}>Show settings</Menu.Item>
    </Menu>
  );

  const dropList = isOperator ? operatorMenuDropList : userMenuDropList;

  return (
    <>
      <ConversationReportDialog onReportSend={handleReportUser} visible={isReportDialogOpened}
                                onClose={() => setIsReportDialogOpened(false)}/>
      {
        isSettingsOpened ? (
          <ChannelSettings
            channelUrl={activeConversation?.url || ''}
            onCloseClick={() => setIsSettingsOpened(false)}
            disableUserProfile
          />
        ) : (
          <GroupChannel
            channelUrl={activeConversation?.url || ''}
            disableUserProfile
            isReactionEnabled={false}
            renderChannelHeader={() => {
              return (
                <div className="conversation__header">
                  {isMobile && (
                    <Button onClick={onCloseConversation} className="conversation__header-button" type='text'>
                      <IconLeft fontSize={24} style={{ color: headerTextColor }}/>
                    </Button>
                  )}
                  <div className="conversation__info">
                    <ConversationAvatar user={respondent} size={32}/>
                    <div>{isOperator ? activeConversation?.name : respondent?.nickname}</div>
                  </div>
                  <Dropdown droplist={dropList} trigger='click' position='br'>
                    <Button className="conversation__header-button" type='text'>
                      <IconMoreVertical fontSize={24} style={{ color: headerTextColor }}/>
                    </Button>
                  </Dropdown>
                </div>
              );
            }}
          />
        )
      }
    </>
  );
}