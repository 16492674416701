import {
  Form,
  Input,
  Button,
  Checkbox,
  Message,
  Upload,
  Modal,
  Typography,
  Select,
  Card,
} from "@arco-design/web-react";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { useEffect, useState } from "react";
import { Grid } from "@arco-design/web-react";
import axios from "axios";
import { endpoints } from "../api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import {
  backgroundColor,
  buttonColor,
  buttonDisabledColor,
  buttonTextColor,
} from "../utils/cssVariables";
import { fixUrl } from "../utils/fixUrl";
import {getAuth} from "firebase/auth";
import { cachedToken } from "../setupAxios";
import { setNewAvatar } from "../store/userSlice";



const Row = Grid.Row;
const Col = Grid.Col;
const TextArea = Input.TextArea;
const FormItem = Form.Item;

export const ProfileSettings = () => {
  const user = useSelector((state: RootState) => state.user.user);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [privateProfile, setPrivateProfile] = useState<any>(null);

  const [categories, setCategories] = useState<string[]>([]);
  const [industries, setIndustries] = useState<string[]>([]);

  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [isAvatarUprating, setIsAvatarUpdating] = useState<boolean>(false);

  const handleChangeIndustries = (value: string[]) => {
    if (value.length > 3) {
      Message.warning("You can only select up to 3 industries.");
      return;
    }
    setSelectedIndustries(value);
    setPrivateProfile({
      ...privateProfile,
      industries: value,
    });
  };
  const [idToken, setIdToken] = useState<string | null>(null);


  useEffect(() => {
    const loadToken = async () => {

      let token = cachedToken;

      if (!token) {
        const user = getAuth().currentUser;
        if (user) {
          token = await user.getIdToken();
        } else {
        }
      }
      if (token) {
        setIdToken(token);
      } else {
        console.warn("No token available for avatar upload");
      }
    };

    void loadToken();
  }, [cachedToken, getAuth().currentUser]);




  useEffect(() => {
    axios
      .get(endpoints.currentProfile)
      .then((response) => {
        setPrivateProfile(response.data);
        setSelectedIndustries(response.data?.industries || [])
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    axios
      .get(endpoints.getSearchFilters)
      .then((response) => {
        setCategories(response.data?.categories || []);
        setIndustries(response.data?.industries || []);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }, []);

  const onSubmit = async () => {
    if (form) {
      form.validate().then(() => {
        axios
          .post(endpoints.updateProfile, privateProfile)
          .then(() => {
            navigate(routes.MENTOR.replace(":id", privateProfile.id));
            Message.success({content: "Profile updated successfully", closable: true, duration: 3000});
          })
          .catch((error) => {
            console.error(error);
            Message.error("Something went wrong");
          })
          .finally(() => {});
      });
    }
  };

  return (
    <Card
      style={{
        width: "100%",
      }}
      className="card-with-icon-hover own-card"
    >
      <Row style={{ margin: 10, marginTop: 0 }} justify="center">
        <Col span={24} xl={{ span: 24 }} xs={{ span: 24 }} md={{ span: 18 }}>
          <Typography.Title
            heading={3}
            style={{
              textAlign: "left",
              margin: "10px 0 20px 0",
              wordBreak: "break-word",
              textTransform: "uppercase",
            }}
          >
            Edit Profile
          </Typography.Title>

          {!!privateProfile && !!privateProfile?.email && (
            <>
              <Form
                form={form}
                autoComplete="off"
                size={"default"}
                scrollToFirstError
              >
                <FormItem layout="vertical" label="Full name" field="name"  rules={[{required: true, message: "Please enter your full name.",  validator: (v, cb) => !v || v.trim().length === 0 ? cb("Please enter your full name.") : cb()}]}>
                  <Input
                    placeholder="Please enter your full name"
                    autoComplete={"off"}
                    defaultValue={privateProfile?.name}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        name: value,
                      });
                    }}
                  />
                  <div style={{ display: "none" }}>
                    {" "}
                    {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Short Description"
                  field="shortDescription"
                  rules={[{ required: false }]}
                >
                  <Input
                    placeholder="Please tell us about yourself"
                    defaultValue={privateProfile?.shortDescription}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        shortDescription: value,
                      });
                    }}
                  />
                  <div style={{ display: "none" }}>
                    {" "}
                    {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Bio"
                  field="fullBio"
                  rules={[{ required: false }]}
                >
                  <TextArea
                    placeholder="Please tell us about yourself"
                    defaultValue={privateProfile?.fullBio}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        fullBio: value,
                      });
                    }}
                    autoSize={{ minRows: 3, maxRows: 10 }}
                    maxLength={300}
                    showWordLimit
                  />
                  <div style={{ display: "none" }}>
                    {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Location"
                  field="location"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    placeholder="please enter your location"
                    defaultValue={privateProfile?.location}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        location: value,
                      });
                    }}
                  />
                  <div style={{ display: "none" }}>
                    {" "}
                    {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                {(user?.isMentor || user?.isAdmin) && <FormItem
                  layout="vertical"
                  label="I can advise you on"
                  field="categories"
                  rules={[{ required: false }]}
                >
                  <Select
                    placeholder="please select your proficiency"
                    mode="multiple"
                    options={categories.map((category) => ({
                      label: category,
                      value: category,
                    }))}
                    allowClear
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        categories: value,
                      });
                    }}
                    defaultValue={privateProfile?.categories}
                  />
                  <div style={{ display: "none" }}>
                    {" "}
                    {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>}

                <FormItem
                  layout="vertical"
                  label="Industry"
                  field="industries"
                  rules={[{ required: false }]}
                >
                  <Select
                    placeholder="Please select your industries"
                    mode="multiple"
                    maxTagCount={3}
                    value={selectedIndustries}
                    options={industries.map((industry) => ({
                      label: industry,
                      value: industry,
                      disabled:
                        selectedIndustries.length >= 3 &&
                        !selectedIndustries.includes(industry),
                    }))}
                    allowClear
                    onChange={handleChangeIndustries}
                    defaultValue={privateProfile?.industries}
                  />
                  <div style={{ display: "none" }}>
                    {" "}
                    {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Booking Link"
                  field="bookingLink"
                  rules={[{ required: false }]}
                >
                  <Input
                    placeholder="please enter your booking link"
                    defaultValue={privateProfile?.bookingLink}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        bookingLink: value,
                      });
                    }}
                  />
                  <div style={{ display: "none" }}>
                    {" "}
                    {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Linkedin"
                  field="linkedIn"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    placeholder="please enter your linkedin profile link"
                    value={privateProfile?.linkedIn}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        linkedIn: value,
                      });
                    }}
                    onBlur={() => {
                      setPrivateProfile({
                        ...privateProfile,
                        linkedIn: fixUrl(privateProfile.linkedIn),
                      });
                    }}
                  />
                  <div style={{ display: "none" }}>
                    {" "}
                    {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Website"
                  field="website"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    placeholder="please enter your website"
                    value={privateProfile?.website}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        website: value,
                      });
                    }}
                    onBlur={() => {
                      setPrivateProfile({
                        ...privateProfile,
                        website: fixUrl(privateProfile.website),
                      });
                    }}
                  />
                  <div style={{ display: "none" }}>
                    {" "}
                    {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <Form.Item
                  layout="vertical"
                  label="Profile Picture"
                  triggerPropName="fileList"
                >
                  <Upload
                    drag
                    withCredentials={true}
                    limit={1}
                    accept="image/jpeg,image/png"
                    action={endpoints.addAvatar}
                    headers={{ Authorization: `Bearer ${idToken}` }}
                    data={{id: user?.id}}
                    tip={"Only jpg/png files are supported"}
                    onChange={(fileList, file) => {
                      if (file.status === "done") {
                        setIsAvatarUpdating(false);
                        const response = file.response as { id: string }
                        const avatarURL: string = response.id
                        setPrivateProfile({
                          ...privateProfile,
                          avatar_url: avatarURL,
                        });
                        Message.success({content: "Avatar uploaded successfully", closable: true, duration: 3000});
                        dispatch(setNewAvatar(avatarURL));
                      }
                    }}
                    onProgress={(e) => {
                      if (e.status === "uploading") {
                        setIsAvatarUpdating(true);
                      }
                    }}
                  />
                </Form.Item>

                <FormItem layout="vertical">
                  <Button
                    htmlType="submit"
                    style={{
                      color: buttonTextColor,
                      backgroundColor: isAvatarUprating ? buttonDisabledColor : buttonColor,
                    }}
                    onClick={onSubmit}
                    disabled={isAvatarUprating}
                  >
                    Update Profile
                  </Button>
                </FormItem>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
};
