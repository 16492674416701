import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Notification,
} from "@arco-design/web-react";
import { IconEdit } from "@arco-design/web-react/icon";

import {
  MAX_COMMENT_LENGTH,
  SYMBOLS_COUNTER_NEEDED_LENGTH,
} from "../constants";
import { CommentApi, PostApi } from "../types/types";
import { getParsedArray } from "../../../../../utils/getParsedArray";

interface IUpdateCommentModalProps {
  comment: CommentApi;
  post: PostApi;
  isMutating: boolean;
  onUpdatePost: (postId: number, updatedPost: PostApi) => void;
}

const UpdateCommentModal = ({
  comment,
  post,
  isMutating,
  onUpdatePost,
}: IUpdateCommentModalProps) => {
  const [visible, setVisible] = useState(false);

  const [updatingComment, setUpdatingComment] = useState(comment.content);

  const showModal = () => {
    setVisible(true);
  };

  const handleUpdate = () => {
    if (updatingComment.trim() === "") {
      Notification.error({
        content: "Please enter your comment",
      });
      return;
    }

    const parsedComments: CommentApi[] = getParsedArray(post.comments);
    const updatedComments = parsedComments.map((prevComment) => {
      if (prevComment.created_at === comment.created_at) {
        return {
          ...comment,
          content: updatingComment,
          edited_at: new Date().toISOString(),
        };
      }
      return prevComment;
    });

    if (post.id) {
      const updatedPost: PostApi = {
        ...post,
        likes: post.likes === "[]" ? JSON.parse(post.likes) : [],
        comments: updatedComments,
      };
      onUpdatePost(post.id, updatedPost);
      setVisible(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setUpdatingComment(comment.content);
  };

  return (
    <div>
      <Button
        style={{
          padding: 0,
          backgroundColor: "transparent",
        }}
        onClick={showModal}
        disabled={isMutating}
      >
        <IconEdit />
      </Button>

      <Modal
        title="Update Your Comment"
        visible={visible}
        onOk={handleUpdate}
        onCancel={handleCancel}
        okText="Update"
        cancelText="Cancel"
        className="feed-modal"
      >
        <Form layout="vertical">
          <Form.Item label="Your comment">
            <Input.TextArea
              value={updatingComment}
              onChange={(value) => setUpdatingComment(value)}
              autoSize
              maxLength={MAX_COMMENT_LENGTH}
              placeholder="Enter your comment"
            />
            {updatingComment?.length >
              SYMBOLS_COUNTER_NEEDED_LENGTH.COMMENT && (
              <div style={{ textAlign: "right" }}>
                {updatingComment?.length ?? 0} / {MAX_COMMENT_LENGTH}
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdateCommentModal;
