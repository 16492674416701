import {
    Form,
    Grid,
    Input,
    Button,
    Tooltip,
    Typography, Message,
} from "@arco-design/web-react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { useEffect, useState } from "react";
import { Logo } from "../components/Logo";
import {
  boxBackgroundColor,
  buttonColor,
  buttonTextColor,
  colorAccent1,
} from "../utils/cssVariables";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import axios from "axios";
import {endpoints} from "../api/endpoints";

const Row = Grid.Row;
const FormItem = Form.Item;

export const ConfirmResetPassword = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const oobCode = params.get("oobCode");
    return { oobCode };
  };

  useEffect(() => {
    if (!!user) {
      const defaultPage = user.isAdmin
        ? routes.ADMIN_DASHBOARD
        : routes.DASHBOARD;
      navigate(defaultPage);
    }
  }, [navigate, user]);

    const onSubmit = async () => {
        if (form) {
            try {
                const values = await form.validate();
                const password = values.password;

                const params = new URLSearchParams(location.search);
                const email = params.get("email");

                if (!email) {
                    Message.error("Invalid or expired reset link.");
                    return;
                }

                await axios.post(endpoints.resetPassword, {
                    email: email,
                    password: password,
                });

                Message.success("Password reset successfully.");
                navigate(routes.LOGIN);
            } catch (error) {
                console.error("Error resetting password:", error);
                Message.error("Failed to reset password.");
            }
        }
    };


  useEffect(() => {}, []);

  return (
    <div
      style={{
        justifyContent: "center",
        backgroundColor: boxBackgroundColor,
        maxWidth: 480,
        width: "calc(100% - 40px)",
        margin: "0 auto",
        padding: "20px",
        borderRadius: 8,
      }}
    >
      <Logo
        size={350}
        style={{
          marginTop: 10,
          maxWidth: "100%",
        }}
      />
      <Form onSubmit={onSubmit} form={form}>
        <Row justify="center">
          <div
            style={{
              padding: "20px",
              width: "calc(100% - 40px)",
              maxWidth: 350,
            }}
          >
            <div style={{ marginTop: 10 }}>
              <Typography.Text
                style={{
                  fontSize: 20,
                  color: colorAccent1,
                  wordBreak: "break-word",
                }}
                type="success"
                className={"alice-regular"}
              >
                {"Reset password"}
              </Typography.Text>
            </div>
            <div style={{ marginTop: 20 }}>
                <FormItem
                  layout="vertical"
                  label="New password"
                  field="password"
                  rules={[
                    {
                      validator: (v, cb) => {
                        return !v
                          ? cb("Password is required")
                          : v.length < 6
                          ? cb("Password must be at least 6 characters long")
                          : cb();
                      },
                    },
                  ]}
                  validateTrigger="onChange"
                >
                  <Input.Password
                    placeholder="Enter your new password"
                    autoComplete={"off"}
                  />
                </FormItem>
                <FormItem
                  layout="vertical"
                  label="Confirm password"
                  field="passwordConfirmation"
                  rules={[
                    {
                      validator: (v, cb) => {
                        return v !== form.getFieldValue("password")
                          ? cb("Passwords do not match")
                          : cb();
                      },
                    },
                  ]}
                  validateTrigger="onChange"
                >
                  <Input.Password
                    placeholder="Confirm your new password"
                    autoComplete={"off"}
                  />
                </FormItem>
            </div>
          </div>
        </Row>

        <FormItem layout="vertical">
          <Button
            htmlType="submit"
            style={{
              marginLeft: 24,
              color: buttonTextColor,
              backgroundColor: buttonColor,
            }}
            size={"large"}
            shape={"round"}
          >
            Reset password
          </Button>
        </FormItem>
      </Form>

      <div style={{ textAlign: "center", marginTop: 20 }}>
        <Typography.Paragraph>
          Remember your credentials?
          <NavLink to={routes.profile}>
            <Button
              style={{ padding: "0 0 0 4px" }}
              type="text"
              status="success"
            >
              <span style={{ color: colorAccent1 }}>Back to login</span>
            </Button>
          </NavLink>
        </Typography.Paragraph>
      </div>
    </div>
  );
};
