import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { endpoints } from "../../../api/endpoints";
import { User } from "../../../models/Person";
import { Button, Card, Form, Message, Spin } from "@arco-design/web-react";
import { UserCard } from "../../../components/UserCard/UserCard";
import { buttonColor, buttonTextColor } from "../../../utils/cssVariables";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as KeyIcon } from "../../../assets/icons/key.svg";
import "./UserProfile.css";
import { routes } from "../../../routes/routes";

import { setSelectedUser } from "../../../store/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { UserDetailedInfo } from "../../../components/UserDetailedInfo/UserDetailedInfo";
import { UserForm } from "../UserForm/UserForm";
import classNames from "classnames";
import { IconLeft } from "@arco-design/web-react/icon";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog/ConfirmationDialog";

export function UserProfile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const currentUser = useSelector((state: RootState) => state.user.user);
  const selectedUser = useSelector((state: RootState) => state.admin.selectedUser);
  const hasUserData = selectedUser?.id.toString() === id;

  const [isLoading, setIsLoading] = useState<boolean>(!hasUserData);
  const [user, setUser] = useState<User | null>(hasUserData ? selectedUser : null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showEditConfirmation, setShowEditConfirmation] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [showPasswordResetConfirmation, setShowPasswordResetConfirmation] = useState<boolean>(false);

  const getProfile = async () => {
    axios
      .get(`${endpoints.admin.getUser}/${id}`)
      .then((response) => {
        setUser(response.data);
        dispatch(
          setSelectedUser(response.data)
        )
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getProfile().then();
  }, []);


  const handleSubmitEdit = () => {
    if (form) {
      const fields = form.getFields();
      const formData = new FormData();

      Object.keys(fields).forEach((key) => {
        if (Array.isArray(fields[key])) {
          fields[key].forEach((value: string) => {
            formData.append(key, value);
          });
        } else {
          formData.append(key, fields[key]);
        }
      });

      form.validate().then(() => {
        axios
          .put(`${endpoints.admin.createUser}/${user?.id}`, formData)
          .then((response) => {
            setUser(response.data);
            setIsEditing(false);
            dispatch(
              setSelectedUser(response.data)
            )
          })
          .catch((error) => {
            console.error(error);
            Message.error("Something went wrong");
          })
          .finally(() => {
            form.resetFields();
          });
      });
    }
  };

  const handleDelete = () => {
    axios
      .delete(`${endpoints.admin.createUser}/${user?.id}`)
      .then(() => {
        dispatch(
          setSelectedUser(null)
        )
        Message.success({content: "User deleted successfully", closable: true, duration: 3000});
        navigate(routes.ADMIN_USERS)
      })
      .catch((error) => {
        console.error(error);
        Message.error("Something went wrong");
      })
  };

  const handlePasswordReset = () => {
    if (user?.email) {
      axios
        .post(endpoints.admin.resetPassword, {
          "email": user.email,
        })
        .then(() => {
          Message.success({content: "A new verification code has been resent to user email.", closable: true, duration: 3000});
        })
        .catch((error) => {
          console.error(error);
          Message.error("Something went wrong. Please try again later.");
        })
    } else {
      Message.error("Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="user-profile">
      <div className="user-profile__header">
        <div className="user-profile__header-left">
          <Button
            onClick={() => {
              navigate(routes.ADMIN_USERS);
            }}
            className="user-profile__icon-button"
          >
            <IconLeft fontSize={24}/>
          </Button>
        <h1 className="user-profile__title">User Profile</h1>
        </div>
        <div className="user-profile__action-buttons">
          <Button
            className={classNames('user-profile__icon-button', { 'user-profile__icon-button--active': isEditing })}
            shape={"round"}
            onClick={() => {
              form.setFieldsValue(user);
              setIsEditing(true);
            }}
          >
            <EditIcon/>
          </Button>
          {selectedUser?.id !== currentUser?.id && <Button
            className="user-profile__icon-button"
            shape={"round"}
            onClick={() => setShowDeleteConfirmation(true)}
          >
            <TrashIcon/>
          </Button>}
          <Button
            className="user-profile__icon-button"
            shape={"round"}
            onClick={() => setShowPasswordResetConfirmation(true)}
          >
            <KeyIcon/>
          </Button>
        </div>
      </div>

      {!user && isLoading && (
        <div className="user-profile__loader-wrapper">
          <Spin size={40}/>
        </div>
      )}
      {!isLoading && user && (
        <div className="user-profile__content">
          {!isEditing && (
            <>
              <UserCard user={user}/>
              <Card className="user-profile__card" bordered={false}>
                <div className="user-profile__card-header">Detailed information</div>
                <UserDetailedInfo user={user}/>
              </Card>
              <Card className="user-profile__card" bordered={false}>
                <div className="user-profile__card-header">About me</div>
                <div className="user-profile__card-text-content">{user.fullBio || '-'}</div>
              </Card>
              {id && (
                <NavLink to={`${routes.ADMIN_USER_BOOKING.replace(":id", id.toString())}`}>
                  <Button
                    style={{
                      color: buttonTextColor,
                      backgroundColor: buttonColor,
                      width: '100%'
                    }}
                    size="large"
                    shape={"round"}
                  >
                    Book meeting
                  </Button>
                </NavLink>
              )}
            </>
          )}

          {isEditing && (
            <UserForm
              role={user.isMentor ? 'mentor' : 'mentee'}
              form={form}
              isEditing
              avatarUrl={user.avatar_url}
              onSubmit={() => setShowEditConfirmation(true)}
              onCancel={() => {
                form.resetFields();
                setIsEditing(false);
              }}
            />
          )}
          <ConfirmationDialog
            visible={showEditConfirmation}
            text="Save the changes?"
            onConfirm={() => {
              handleSubmitEdit();
              setIsEditing(false);
              setShowEditConfirmation(false);
            }}
            onCancel={() => {
              setShowEditConfirmation(false);
              setIsEditing(false);
              form.resetFields();
            }}
          />
          <ConfirmationDialog
            visible={showDeleteConfirmation}
            text="Are you sure you want to delete the profile?"
            onConfirm={() => {
              handleDelete();
              setShowDeleteConfirmation(false);
            }}
            onCancel={() => {
              setShowDeleteConfirmation(false);
            }}
          />
          <ConfirmationDialog
            visible={showPasswordResetConfirmation}
            text="Are you sure you want to reset the password?"
            onConfirm={() => {
              handlePasswordReset();
              setShowPasswordResetConfirmation(false);
            }}
            onCancel={() => {
              setShowPasswordResetConfirmation(false);
            }}
          />
        </div>
      )}
    </div>
  );
}