import "./UserForm.css";
import {
  Button,
  Form,
  FormInstance,
  Input,
  Message,
  Select,
  Upload,
} from "@arco-design/web-react";
import { Role } from "../../../models/Person";
import { useState } from "react";
import {
  AGE_LIST,
  AREA_OF_INTERESTS_LIST,
  BOROUGH_LIST,
  CATEGORY_LIST,
  EDUCATION_LIST,
  ETHNICITY_LIST,
  GENDER_LIST,
  HIGH_GROWTH_INDUSTRY_LIST,
  HOW_DID_YOU_HEAR_ABOUT_US_LIST,
  INCOME_RANGE_LIST,
  INDUSTRIES_LIST,
  OCCUPATION_STATUS_LIST,
  PROFESSIONAL_EXPERIENCE_LIST,
  RACE_LIST,
} from "../../../utils/userFormLisitsConstants";
import {
  buttonColor,
  buttonDisabledColor,
  buttonTextColor,
} from "../../../utils/cssVariables";
import { fixUrl } from "../../../utils/fixUrl";

type Props = {
  role: Role;
  form: FormInstance;
  avatarUrl?: string;
  isEditing?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

const TextArea = Input.TextArea;

export function UserForm({ form, role, avatarUrl: currentAvatarUrl, isEditing, onSubmit, onCancel }: Props) {
  const [avatar, setAvatar] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState(currentAvatarUrl || form.getFieldValue("avatarUrl"));

  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);

  const handleChangeIndustries = (value: string[]) => {
    if (value.length > 3) {
      Message.warning("You can only select up to 3 industries.");
      return;
    }
    setSelectedIndustries(value || []);
  };

  const getOptions = (options: string[]) => {
    return options.map((option) => ({
      label: option,
      value: option,
    }));
  };

  const getIndustriesOptions = (options: string[]) => {
    return options.map((option) => ({
      label: option,
      value: option,
      disabled:
        selectedIndustries.length >= 3 && !selectedIndustries.includes(option),
    }));
  };

  const handleSubmit = () => {
    if (!avatar) form.setFieldsValue({ avatar_url: null });
    onSubmit();
  };

  const renderFormErrorMessage = (message: string) => (
    <span style={{ textTransform: "none" }}>{message}</span>
  )

  return (
    <Form
      form={form}
      autoComplete="off"
      size="default"
      scrollToFirstError
      onSubmit={handleSubmit}
      className="create-user-form"
    >
      <Form.Item
        layout="vertical"
        triggerPropName="fileList"
        className="create-user-form__avatar-upload"
      >
        <Upload
          drag
          limit={1}
          autoUpload={false}
          imagePreview
          defaultFileList={
            avatarUrl
              ? [
                {
                  uid: form.getFieldValue("id"),
                  url: currentAvatarUrl || form.getFieldValue("avatarUrl"),
                },
              ]
              : undefined
          }
          listType="picture-card"
          accept="image/jpeg,image/png"
          onChange={(fileList, file) => {
            if (file.originFile) {
              form.setFieldsValue({ file: file.originFile });
              setAvatar(file.originFile);
              setAvatarUrl(null);
            };
          }}
          onRemove={(fileList) => {
            fileList.status = "init";
            fileList.originFile = undefined;
            setAvatar(null);
            setAvatarUrl(null);
          }}
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="Name"
        field="name"
        rules={[{ required: true, message: renderFormErrorMessage("Please enter the user's full name."), validator: (v, cb) => !v || v.trim().length === 0 ? cb(renderFormErrorMessage("Please enter the user's full name.")) : cb() }]}
      >
        <Input
          placeholder="Please enter the user's name"
          autoComplete="off"
          className="create-user-form__input"
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="Email"
        field="email"
        rules={[{ required: true, message: renderFormErrorMessage("Please enter the user's email address.") }, {
          validator: (v, cb) => {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!v || v.trim().length === 0) {
              cb(renderFormErrorMessage("Please enter the user's email address."));
            } else if (v.length > 100) {
              cb(renderFormErrorMessage("Email address is too long."));
            } else if (!emailRegex.test(v)) {
              cb(renderFormErrorMessage("Please enter a valid email address."));
            } else {
              cb();
            }
          },
        }]}
      >
        <Input
          placeholder="Please enter email"
          autoComplete="off"
          className="create-user-form__input"
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="LinkedIn"
        field="linkedIn"
        rules={[{ required: true, message: renderFormErrorMessage("Please enter the user's LinkedIn profile link.") }, {
          validator: (v, cb) => {
            const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/;
            const linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.+/i;

            if (!v || v.trim().length === 0) {
              cb(renderFormErrorMessage("Please enter the user's LinkedIn profile link."));
            } else if (!urlRegex.test(v)) {
              cb(renderFormErrorMessage("Please enter a valid URL."));
            } else if (!linkedinRegex.test(v)) {
              cb(renderFormErrorMessage("The link must be a valid LinkedIn profile URL. Example: https://linkedin.com/in/username/"));
            } else cb();
          },
        },]}
      >
        <Input
          placeholder="Please enter LinkedIn"
          autoComplete="off"
          className="create-user-form__input"
          onBlur={() => {
            form.setFieldsValue({
              linkedIn: fixUrl(form.getFieldValue("linkedIn")),
            });
          }}
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="Location"
        field="location"
      >
        <Input type="text" placeholder="Please enter your location" />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="Borough"
        field="borough"
      >
        <Select
          placeholder="Please enter your borough"
          options={getOptions(BOROUGH_LIST)}
          allowClear
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="Gender"
        field="gender"
      >
        <Select
          placeholder="Please enter your gender"
          options={getOptions(GENDER_LIST)}
          allowClear
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="Age"
        field="age"
      >
        <Select
          placeholder="Please enter your age"
          options={getOptions(AGE_LIST)}
          allowClear
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="Race"
        field="race"
      >
        <Select
          placeholder="Please enter your race"
          options={getOptions(RACE_LIST)}
          allowClear
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="Ethnicity"
        field="ethnicity"
      >
        <Select
          placeholder="Please enter your ethnicity"
          options={getOptions(ETHNICITY_LIST)}
          allowClear
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="Education"
        field="education"
      >
        <Select
          placeholder="Please enter your education"
          options={getOptions(EDUCATION_LIST)}
          allowClear
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="Income Range"
        field="incomeRange"
      >
        <Select
          placeholder="Please enter your income range"
          options={getOptions(INCOME_RANGE_LIST)}
          allowClear
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="Occupation Status"
        field="occupationStatus"
      >
        <Select
          placeholder="Please enter your occupation status"
          options={getOptions(OCCUPATION_STATUS_LIST)}
          allowClear
        />
      </Form.Item>
      <Form.Item
        layout="vertical"
        label="What is your professional experience level?"
        field="professionalExperience"
      >
        <Select
          placeholder="Please enter your professional experience level"
          options={getOptions(PROFESSIONAL_EXPERIENCE_LIST)}
          allowClear
        />
      </Form.Item>
      {role !== 'mentee' && <Form.Item
        layout="vertical"
        label="I can advise you on"
        field="categories"
      >
        <Select
          placeholder="Please select your proficiency"
          mode="multiple"
          options={getOptions(CATEGORY_LIST)}
          allowClear
        />
      </Form.Item>}
      <Form.Item
        layout="vertical"
        label="Current Industry"
        field="industries"
      >
        <Select
          placeholder="Please select your industries"
          mode="multiple"
          maxTagCount={3}
          value={selectedIndustries}
          onChange={handleChangeIndustries}
          options={getIndustriesOptions(INDUSTRIES_LIST)}
          allowClear
        />
      </Form.Item>
      {role === "mentor" && (
        <Form.Item
          layout="vertical"
          label="Booking Link"
          field="bookingLink"
          rules={[
            { required: false },
          ]}
        >
          <Input
            placeholder="please enter your booking link"
          />
        </Form.Item>
      )}
      {role === "mentee" && (
        <Form.Item
          layout="vertical"
          label="Which high growth industry interests you the most?"
          field="highGrowthIndustries"
        >
          <Select
            placeholder="Please select industries"
            mode="multiple"
            options={getOptions(HIGH_GROWTH_INDUSTRY_LIST)}
            allowClear
          />
        </Form.Item>
      )}

      {role === "mentee" && !isEditing && (
        <Form.Item
          layout="vertical"
          label="Which area interests you the most?"
          field="areaOfInterests"
        >
          <Select
            placeholder="Please select area of interests"
            mode="multiple"
            options={getOptions(AREA_OF_INTERESTS_LIST)}
            allowClear
          />
        </Form.Item>
      )}

      {role === "mentee" && !isEditing && (
        <Form.Item
          layout="vertical"
          label="How did you find out about this event?"
          field="howYOuFindUs"
        >
          <Select
            placeholder="Please select the source"
            options={getOptions(HOW_DID_YOU_HEAR_ABOUT_US_LIST)}
            allowClear
          />
        </Form.Item>
      )}

      <Form.Item layout="vertical" label="Website" field="website">
        <Input
          type="text"
          placeholder="Please enter your website"
          onBlur={() => {
            form.setFieldsValue({
              website: fixUrl(form.getFieldValue("website")),
            });
          }}
        />
      </Form.Item>

      <Form.Item
        layout="vertical"
        label="Short Description"
        field="shortDescription"
        rules={[{ required: false }]}
      >
        <Input
          type="text"
          placeholder="Please enter a short description, e.g. 'Software Engineer'"
        />
      </Form.Item>

      <Form.Item
        layout="vertical"
        label="Bio"
        field="fullBio"
        rules={[{ required: false }]}
      >
        <TextArea
          placeholder="Please tell us about yourself"
          autoSize={{ minRows: 3, maxRows: 10 }}
          maxLength={300}
          showWordLimit
        />
      </Form.Item>

      <Form.Item layout="horizontal" wrapperCol={{ span: 24 }}>
        <div className="create-user-form__footer">
          <Button
            className="create-user-form__footer-button create-user-form__secondary-button"
            size="large"
            shape={"round"}
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            className="create-user-form__footer-button create-user-form__primary-button"
            size="large"
            shape={"round"}
            style={{
              color: buttonTextColor,
              backgroundColor: buttonColor,
            }}
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}
