import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { Avatar, Button } from "@arco-design/web-react";

import { downloadCSVReport } from "./downloadCSVReport";
import "./Statistics.css";
import { StatisticsData } from "./types";

import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { endpoints } from "../../../api/endpoints";

const fetchStatistics = async () => {
  const response = await axios.get(endpoints.admin.getStatistics);
  return response.data as StatisticsData;
};

export const Statistics = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["statistics"],
    queryFn: fetchStatistics,
  });

  const renderTopUsersSkeletons = () => {
    const items = [1, 2, 3];

    return items.map((key, index) => (
      <div key={key} className="top-list-item">
        <p className="place">{index + 1}</p>
        <div className="user">
          <Avatar className="user-avatar" />
          <span className="user-name skeleton" />
        </div>
      </div>
    ));
  };

  const renderTopCategorySkeletons = () => {
    const items = [1, 2, 3];
    
    return items.map((key, index) => (
      <div key={key} className="top-list-item">
        <p className="place">{index + 1}</p>
        <span className="distribution-name skeleton" />
      </div>
    ));
  };
  
  return (
    <section className="statistics">
      <h1 className="statistics__title">Statistics</h1>
      <div className="statistics__list">
        <div className="statistics__list-header">
          <h2 className="statistics__list-header-title">Booked Sessions</h2>
          <CalendarIcon />
        </div>
        <div className="statistics__list-content">
          <div className="card booked-session">
            <p className="card-title">
              {isLoading ? "-" : data?.total_sessions || 0}
            </p>
            <span className="card-description">
              Total number of booked sessions
            </span>
          </div>
          <div className="card booked-session">
            <p className="card-title">
              {isLoading
                ? "-"
                : data && `${data.confirmed_users || 0}/${data.total_users || 0}`}
            </p>
            <span className="card-description">Total number of users</span>
          </div>
          <div className="card booked-session">
            <p className="card-title">
              {isLoading  ? "-" : data?.mentors || 0}
            </p>
            <span className="card-description">Mentors</span>
          </div>
          <div className="card booked-session">
            <p className="card-title">
              {isLoading ? "-" : data?.mentees || 0}
            </p>
            <span className="card-description">Mentee</span>
          </div>
        </div>
      </div>
      <div className="statistics__list">
        <div className="statistics__list-header">
          <h2 className="statistics__list-header-title">Top 3 active users</h2>
        </div>
        <div className="statistics__list-content">
          <div className="top-list">
            {data?.top_active_users ? data?.top_active_users.map((user, index) => {
              return (
                <div key={user.user_id} className="top-list-item">
                  <p className="place">{index + 1}</p>
                  <div className="user">
                    <Avatar className="user-avatar">
                      <img src={user.avatar_url} alt={user.name} />
                    </Avatar>
                    <span className="user-name">{user.name}</span>
                  </div>
                </div>
              );
            }) : !isLoading && <span>Currently, there is no available data to display the top 3 active users. This section will be updated once the necessary statistics are collected and processed. Please check back later for insights into user activity.</span>}
            {isLoading && renderTopUsersSkeletons()}
          </div>
        </div>
      </div>
      <div className="statistics__list">
        <div className="statistics__list-header">
          <h2 className="statistics__list-header-title">
            Top 3 Category/Industry Breakdown
          </h2>
        </div>
        <div className="statistics__list-content">
          <div className="top-list">
            {data?.top_distribution ? data?.top_distribution.map((distribution, index) => {
              return (
                <div key={distribution.name} className="top-list-item">
                  <p className="place">{index + 1}</p>
                  <span className="distribution-name">{distribution.name}</span>
                </div>
              );
            }) : !isLoading && <span>Data regarding the top 3 categories/industries is still being compiled. Once the analysis is complete, this section will provide a detailed breakdown of the most active or relevant categories. Stay tuned for updates!</span>}
            {isLoading && renderTopCategorySkeletons()}
          </div>
        </div>
      </div>
      <Button onClick={downloadCSVReport} className="statistics__action-button">
        Generate CSV Report
      </Button>
    </section>
  );
};
