import './CreateUser.css';
import { useState } from "react";
import { RoleDropdown } from "../../../components/RoleDropdown/RoleDropdown";
import { Role } from "../../../models/Person";
import { Form, Message } from "@arco-design/web-react";
import axios from "axios";
import { endpoints } from "../../../api/endpoints";
import { useNavigate } from "react-router-dom";
import { SuccessScreen } from "../../../components/SuccessScreen/SuccessScreen";
import { UserForm } from "../UserForm/UserForm";

export function CreateUser() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [role, setRole] = useState<Role>('mentor');
  const [isUserCreated, setIsUserCreated] = useState<boolean>(false);

  const onSubmit = () => {
    if (form) {
      const fields = form.getFields();
      const formData = new FormData();

      formData.append("isMentor", Boolean(role === "mentor").toString());

      Object.keys(fields).forEach((key) => {
        if (Array.isArray(fields[key])) {
          fields[key].forEach((value: string) => {
            formData.append(key, value);
          });
        } else {
          formData.append(key, fields[key]);
        }
      });

      form.validate().then(() => {
        axios
          .post(endpoints.admin.createUser, formData)
          .then(() => {
            setIsUserCreated(true);
          })
          .catch((error) => {
            console.error(error);
            if(error.response && error.response.status === 409) {
              Message.error(
                "This email is already registered. Please use a different email."
              );
            } else if (typeof error === "object" && error !== null) {
              const err = error as Record<string, any>;

              // Check for backend error structure
              if (
                err.error?.code === 400 &&
                err.error.message === "EMAIL_EXISTS"
              ) {
                Message.error(
                  "This email is already registered. Please use a different email."
                );
              } else if (err.error?.code === 500) {
                Message.error("Server error. Please try again later.");
              } else if (err.code) {
                // Firebase error handling
                switch (err.code) {
                  case "auth/email-already-in-use":
                    Message.error(
                      "This email is already in use. Please use a different email."
                    );
                    break;
                  case "auth/invalid-email":
                    Message.error("Please enter a valid email address.");
                    break;
                  default:
                    Message.error(
                      "An error occurred during registration. Please try again."
                    );
                }
              } else {
                // General error
                Message.error(
                  "An unexpected error occurred. Please try again."
                );
              }
            } else {
              // Unknown error type
              Message.error("An unexpected error occurred. Please try again.");
            }
          })
          .finally(() => {});
      });
    }
  };

  return (
    <div className="create-user">
      {!isUserCreated && (
        <>
          <h1 className="create-user__title">Create user</h1>
          <RoleDropdown onRoleChange={setRole} role={role}/>
          <div className="create-user__form-wrapper">
            <UserForm
              role={role}
              form={form}
              onSubmit={onSubmit}
              onCancel={() => {
                navigate(-1);
              }}
            />
          </div>
        </>
      )}
      {isUserCreated && (
        <SuccessScreen
          title="You have successfully created a profile"
          buttonText="Create more"
          onButtonClick={() => {
            form.resetFields();
            setIsUserCreated(false);
          }}
        />
      )}
    </div>
  );
}
