import { Layout } from "@arco-design/web-react";
import Content from "@arco-design/web-react/es/Layout/content";
import Footer from "@arco-design/web-react/es/Layout/footer";
import Header from "@arco-design/web-react/es/Layout/header";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";

import { backgroundColor, headerColor } from "../utils/cssVariables";
import { FooterContent } from "./FooterContent";

import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { BaseMenu } from "./BaseMenu";
import { useLocation, useNavigate } from "react-router-dom";

import { routes } from "../routes/routes";
import { useEffect } from "react";

export const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // console.log("location", location.pathname); // e.g. "location /dashboard"

  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (!!user && user.id !== 0) {
      // console.log("user_logged_in", user);
    } else if (!!user && user.id === 0) {
      // console.log("user_logged_out", user);
      if (
        location.pathname !== routes.LOGIN &&
        location.pathname !== routes.REGISTER &&
        location.pathname !== routes.RESETPASSWORD
      ) {
        navigate(routes.LOGIN);
      }
    }
  }, [location.pathname, navigate, user]);

  const shouldAlignCenter =
      location.pathname === routes.LOGIN ||
      location.pathname === routes.REGISTER ||
      location.pathname === routes.RESETPASSWORD;

  return (
    <Layout style={{ height: "100vh" }}>
      <SendbirdProvider
        appId={process.env.REACT_APP_SENDBIRD_APP_ID || ''}
        userId={user?.id ? user.id.toString() : ''}
        uikitOptions={{
          groupChannel: {
            input: {
              camera: {
                enablePhoto: false,
                enableVideo: false,
              },
              gallery: {
                enablePhoto: false,
                enableVideo: false,
              },
              enableDocument: false,
            },
            replyType: 'none',
          }
        }}
      >
        <Layout>
          {location.pathname !== routes.LOGIN &&
            location.pathname !== routes.REGISTER &&
              location.pathname !== routes.RESETPASSWORD && location.pathname !== routes.CONFIRM_EMAIL && location.pathname !== routes.CONFIRM_RESET_PASSWORD && (
              <Header style={{ backgroundColor: headerColor }}>
                <BaseMenu/>
              </Header>
            )}
          <Layout>
            <Content
              style={{
                textAlign: "center",
                alignContent: shouldAlignCenter ? "center" : undefined,
                backgroundColor: backgroundColor,
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  maxWidth: 800,
                  width: "100%",
                  height: "100%",
                  margin: "0 auto",
                }}
              >
                {children}
              </div>
            </Content>
            {location.pathname !== routes.LOGIN &&
              location.pathname !== routes.REGISTER &&
              location.pathname !== routes.RESETPASSWORD && (
                <Footer style={{ backgroundColor: headerColor }}>
                  <FooterContent/>
                </Footer>
              )}
          </Layout>
        </Layout>
      </SendbirdProvider>
    </Layout>
  );
};
