import React, { useState } from "react";
import { Modal, Button } from "@arco-design/web-react";

import { useBreakpoint } from "../../utils/hooks/useBreakpoint";


interface IDeleteMeetingModalProps {
  onDeleteMeeting: () => void;
}

const DeleteMeetingModal = ({ onDeleteMeeting }: IDeleteMeetingModalProps) => {
  const [visible, setVisible] = useState(false);

  const { isMobile } = useBreakpoint('mobile');

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    onDeleteMeeting();
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      <Button
        className="meeting-card__button meeting-card__button-secondary"
        shape={"round"}
        size={isMobile ? "small" : "large"}
        onClick={showModal}
      >
        Delete
      </Button>

      <Modal
        title="Delete Meeting"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Delete"
        cancelText="Cancel"
        className="meeting-modal"
      >
        <p>Are you sure you want to delete this meeting?</p>
      </Modal>
    </div>
  );
};

export default DeleteMeetingModal;
