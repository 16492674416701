import React, { useEffect } from "react";
import {
  GroupChannelListItemBasicProps
} from "@sendbird/uikit-react/types/modules/GroupChannelList/components/GroupChannelListItem/GroupChannelListItemView";
import { Member } from "@sendbird/chat/lib/__definition";
import { getUserFromConversation } from "../../../utils/getUserFromConversation";
import { useSendbirdStateContext } from "@sendbird/uikit-react";

import './ConversationPreview.css';
import { formatTimestamp } from "../../../utils/formatTimestamp";
import { ConversationAvatar } from "../../ConversationAvatar/ConversationAvatar";

type Props = {
  conversation: GroupChannelListItemBasicProps;
  isSelected: boolean;
  onClick: () => void;
}

export function ConversationPreview({ conversation, isSelected, onClick }: Props) {
  const { stores: { userStore } } = useSendbirdStateContext();

  const [respondent, setRespondent] = React.useState<Member | null>(null);
  const lastMessage = conversation.channel.lastMessage;

  useEffect(() => {
    const respondent = getUserFromConversation(conversation.channel, userStore.user);
    setRespondent(respondent);
  }, [conversation])

  return (
    <button onClick={onClick} className={`conversation-preview ${isSelected ? 'conversation-preview_selected' : ''}`}>
      <div className="conversation-preview__avatar">
      <ConversationAvatar user={respondent} size={32} />
      </div>
      <div className="conversation-preview__info">
        <div className="conversation-preview__top-row">
          <div className="conversation-preview__name">
            {respondent?.nickname}
            {conversation.channel.unreadMessageCount > 0 && (
              <span className="conversation-preview__unread-count">{
                conversation.channel.unreadMessageCount
              }</span>
            )}
          </div>
          <div className="conversation-preview__last-message-time">
            {lastMessage ? formatTimestamp(lastMessage.createdAt) : ''}
          </div>
        </div>
        <div className="conversation-preview__last-message">
          {conversation.isTyping ? 'typing...' : lastMessage?.message}
        </div>

      </div>
    </button>
  );
}