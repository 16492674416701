import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Message,
  Popconfirm,
  Select,
  Table,
  TimePicker,
  Typography,
} from "@arco-design/web-react";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { Grid } from "@arco-design/web-react";
import axios from "axios";
import { endpoints } from "../api/endpoints";
import { useEffect, useState } from "react";
import { buttonColor, buttonTextColor } from "../utils/cssVariables";
import dayjs from "dayjs";

const Row = Grid.Row;
const Col = Grid.Col;
const FormItem = Form.Item;

// const show24h = !Intl.DateTimeFormat('en-US', {hour: 'numeric'}).resolvedOptions().hour12;
const show24h = false;

export const MentorSettings = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const onSubmit = async () => {
    if (form) {
      console.log(form.getFields());
      form.validate().then(() => {
        const fields = form.getFields();

        // console.log(fields);

        if (!fields.date || !fields.date[0] || !fields.date[1]) {
          Message.error("Please select a date");
          return;
        }

        if (!fields.time || !fields.time[0] || !fields.time[1]) {
          Message.error("Please select a time");
          return;
        }

        if (!fields.weekdays || fields.weekdays.length === 0) {
          Message.error("Please select at least one weekday");
          return;
        }

        const timeToHourNumber = (time: string) => {
          if (time.endsWith("PM") && !time.startsWith("12")) {
            return parseInt(time.split(":")[0]) + 12;
          }
          if (time.endsWith("AM") && time.startsWith("12")) {
            return 0;
          }
          return parseInt(time.split(":")[0]);
        };

        let data = {
          from_date: fields.date[0],
          to_date: fields.date[1],
          from_time: timeToHourNumber(fields.time[0]),
          to_time: timeToHourNumber(fields.time[1]),
          weekdays: fields.weekdays,
          timezone: timezone,
          active: true,
        } as any;

        if (!data.weekdays) {
          data.weekdays = [1, 2, 3, 4, 5];
        }

        // console.log(data);

        axios
          .post(endpoints.addSlot, data)
          .then((response) => {
            Message.success({content: "Slot added Successfully", closable: true, duration: 3000});
            loadSlots();
          })
          .catch((error) => {
            console.error(error);
            Message.error("Bad data");
          })
          .finally(() => {});
      });
    }
  };

  const [allSlots, setAllSlots] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadSlots = async () => {
    setLoading(true);
    axios
      .get(endpoints.getSlots)
      .then((response) => {
        setAllSlots(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatToAMPM = (hour: number) => {
    const period = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    return `${hour12} ${period}`;
  };

  useEffect(() => {
    loadSlots();
  }, []);

  const dayToString = (day: number) => {
    switch (day) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "Unknown";
    }
  };

  return (
    <>
      <Card
        style={{
          width: "100%",
        }}
        className="card-with-icon-hover own-card"
      >
        <Typography.Title
          heading={3}
          style={{
            textAlign: "left",
            margin: "10px 0 20px 0",
            wordBreak: "break-word",
            textTransform: "uppercase",
          }}
        >
          Your slots
        </Typography.Title>

        {(!!allSlots && allSlots.length > 0 && (
          <Table
            data={allSlots}
            loading={loading}
            rowKey="id"
            pagination={false}
            style={{ width: "100%" }}
            tableLayoutFixed={true}
            columns={[
              {
                title: "Dates",
                dataIndex: "from_date",
                key: "from_date",
                render: (text, record: any) => (
                  <Typography.Text style={{ wordBreak: "break-word" }}>
                    From {record.from_date} to {record.to_date}
                  </Typography.Text>
                ),
                width: 110,
              },
              {
                title: "Hours",
                dataIndex: "from_time",
                key: "from_time",
                render: (text, record: any) => (
                  <Typography.Text style={{ wordBreak: "break-word" }}>
                    {formatToAMPM(record.from_time)} -{" "}
                    {formatToAMPM(record.to_time)}
                  </Typography.Text>
                ),
                width: 110,
              },
              {
                title: "Days",
                dataIndex: "weekdays",
                key: "weekdays",
                render: (text, record: any) => (
                  <Typography.Text style={{ wordBreak: "break-word" }}>
                    {!!record.weekdays &&
                      record.weekdays.map((day: number) => (
                        <span key={day}>{dayToString(day)} </span>
                      ))}
                  </Typography.Text>
                ),
                width: 170,
              },
              {
                title: "Timezone",
                dataIndex: "timezone",
                key: "timezone",
                width: 110,
                render: (text, record: any) => (
                  <Typography.Text style={{ wordBreak: "break-word" }}>
                    {record.timezone.split("/").join(" ")}
                  </Typography.Text>
                ),
              },
              {
                title: "Actions",
                dataIndex: "id",
                key: "id",
                render: (text, record: any) => (
                  <Popconfirm
                    title="Are you sure to delete this slot?"
                    onOk={() => {
                      axios
                        .delete(`${endpoints.deleteSlot}${record.id}`)
                        .then((response) => {
                          loadSlots();
                          Message.success({content: "Slot deleted", closable: true, duration: 3000});
                        })
                        .catch((error) => {
                          console.error(error);
                          Message.error("Failed to delete slot");
                        })
                        .finally(() => {});
                    }}
                    okText="Yes, delete"
                    cancelText="No, keep"
                    okButtonProps={{
                      size: "large",
                      style: {
                        color: buttonTextColor,
                        backgroundColor: buttonColor,
                      },
                    }}
                    cancelButtonProps={{
                      size: "large",
                      style: {
                        color: buttonTextColor,
                        backgroundColor: buttonColor,
                      },
                    }}
                  >
                    <Button size={"small"}>Delete</Button>
                  </Popconfirm>
                ),
                width: 100,
              },
            ]}
          />
        )) || (
          <Typography.Text style={{ textAlign: "center", width: "100%" }}>
            No slots added, add some below
          </Typography.Text>
        )}
      </Card>

      <Card
        style={{
          width: "100%",
          marginTop: 20,
        }}
        className="card-with-icon-hover own-card"
      >
        <Typography.Title
          heading={3}
          style={{
            textAlign: "left",
            margin: "10px 0 20px 0",
            wordBreak: "break-word",
            textTransform: "uppercase",
          }}
        >
          Add slots to your calendar
        </Typography.Title>
        <Form onSubmit={onSubmit} form={form}>
          <Row justify="center">
            <Col span={24} style={{ margin: 20, padding: 10 }}>
              <Row justify="center">
                <Col
                  span={24}
                  xl={{ span: 14 }}
                  xs={{ span: 24 }}
                  md={{ span: 18 }}
                >
                  <FormItem
                    id="date"
                    field="date"
                    layout="vertical"
                    label="Select slot beginning and ending date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker.RangePicker
                      style={{ width: "100%" }}
                      disabledDate={(current) => {
                        return current.isBefore(dayjs().subtract(1, 'day'));
                      }}
                    />
                  </FormItem>

                  <FormItem
                    id="weekdays"
                    field="weekdays"
                    layout="vertical"
                    label="Select which days you are available for meetings"
                    rules={[{ required: true }]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Please select weekdays"
                      defaultValue={[1, 2, 3, 4, 5]}
                    >
                      <Select.Option value={0}>Sunday</Select.Option>
                      <Select.Option value={1}>Monday</Select.Option>
                      <Select.Option value={2}>Tuesday</Select.Option>
                      <Select.Option value={3}>Wednesday</Select.Option>
                      <Select.Option value={4}>Thursday</Select.Option>
                      <Select.Option value={5}>Friday</Select.Option>
                      <Select.Option value={6}>Saturday</Select.Option>
                    </Select>
                  </FormItem>

                  <FormItem
                    label="This is your current timezone, the hours will be in this timezone"
                    layout="vertical"
                    rules={[{ required: true }]}
                  >
                    <Input disabled value={timezone} />
                  </FormItem>

                  <FormItem
                    id="time"
                    field="time"
                    layout="vertical"
                    label="Select your available hours for the selected days"
                    rules={[{ required: true }]}
                  >
                    <TimePicker.RangePicker
                      use12Hours={true}
                      defaultValue={[
                        dayjs("08:00", "HH:mm"),
                        dayjs("17:00", "HH:mm"),
                      ]}
                      format="hh:mm A"
                      step={{
                        hour: 1,
                        minute: 60,
                        second: 60,
                      }}
                      disableConfirm
                      // scrollSticky={false}
                      onSelect={(e) => {
                        form.setFieldValue("time", e)
                      }}
                      style={{ width: "100%" }}
                    />
                  </FormItem>

                  <Row justify="center" style={{ marginTop: 10 }}>
                    <Col span={24}>
                      <Button
                        htmlType="submit"
                        style={{
                          color: buttonTextColor,
                          backgroundColor: buttonColor,
                          width: "100%",
                        }}
                      >
                        Add slot
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};
