export const routes = {
  LOGIN: "/login",
  REGISTER: "/register",
  RESETPASSWORD: "/reset-password",
  CONFIRM_RESET_PASSWORD: "/confirm-reset-password",
  CONFIRM_EMAIL: "/confirm-email",

  DASHBOARD: "/dashboard",
  SEARCH: "/search",

  MENTOR: "/mentor/:id",
  MENTORSETTINGS: "/mentor-settings",

  profile: "/profile",

  SETTINGS: "/settings",

  NOTFOUND: "/404",
  MESSAGES: "/messages",

  ADMIN_USERS: "/admin/users",
  ADMIN_USER: "/admin/user/:id",
  ADMIN_USER_BOOKING: "/admin/user/:id/booking",
  ADMIN_USER_CREATE: "/admin/user/create",
  ADMIN_MEETINGS: "/admin/meetings",
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_STATISTICS: "/admin/statistics",
};
