import { ReactNode, useEffect, useRef, useState } from "react";
import { IconDoubleLeft, IconDoubleRight } from "@arco-design/web-react/icon";
import { useBreakpoint } from "../../utils/hooks/useBreakpoint";
import './HorizontalScrollableContainer.css';

type Props = {
  children: ReactNode;
}

const SCROLL_DELTA = 350;

export function HorizontalScrollableContainer({ children }: Props) {
  const { isMobile } = useBreakpoint('mobile');

  const scrollableWrapper = useRef<HTMLDivElement>(null);
  const scrollableContainer = useRef<HTMLDivElement>(null);

  const [leftButtonVisible, setLeftButtonVisible] = useState<boolean>(false);
  const [rightButtonVisible, setRightButtonVisible] = useState<boolean>(true);

  const shouldShowRightButton = (scrollLeft: number) => {
    if (!scrollableContainer.current || !scrollableWrapper.current) {
      return true;
    }

    return scrollLeft + scrollableWrapper.current.offsetWidth < scrollableContainer.current.scrollWidth;
  }

  const handleScrollLeft = () => {
    if (scrollableContainer.current) {
      const scrollLeft = scrollableContainer.current.scrollLeft - SCROLL_DELTA;
      scrollableContainer.current.scroll({
        left: scrollLeft,
        behavior: 'smooth'
      });

      setLeftButtonVisible(scrollLeft > 0);
      setRightButtonVisible(shouldShowRightButton(scrollLeft));
    }
  }

  const handleScrollRight = () => {
    if (scrollableContainer.current) {
      const scrollLeft = scrollableContainer.current.scrollLeft + SCROLL_DELTA;
      scrollableContainer.current.scroll({
        left: scrollLeft,
        behavior: 'smooth'
      });

      setLeftButtonVisible(scrollLeft > 0);
      setRightButtonVisible(shouldShowRightButton(scrollLeft));
    }
  }

  useEffect(() => {
    setRightButtonVisible(shouldShowRightButton(0));
  }, []);

  return (
    <div className="horizontal-scrollable-container__wrapper" ref={scrollableWrapper}>
      {!isMobile && leftButtonVisible && (
        <button
          className="horizontal-scrollable-container__button horizontal-scrollable-container__button-left"
          onClick={handleScrollLeft}
        >
          <IconDoubleLeft fontSize={32}/>
        </button>
      )}
      <div className="horizontal-scrollable-container" ref={scrollableContainer}>
        {children}
      </div>
      {!isMobile && rightButtonVisible && (
        <button
          className="horizontal-scrollable-container__button horizontal-scrollable-container__button-right"
          onClick={handleScrollRight}
        >
          <IconDoubleRight fontSize={32}/>
        </button>
      )}
    </div>
  )
}