import { useInfiniteQuery, useMutation } from "@tanstack/react-query";

import {
  Card,
  List,
  Typography,
  Notification,
  Spin,
} from "@arco-design/web-react";

import { CreatePostModal, Post } from "./components";
import "./Feed.css";
import {
  PostApi,
  Post as PostType,
  Comment as CommentType,
} from "./types/types";

import { LoggedUser } from "../../../../models/Person";
import {
  createPostRequest,
  deletePostRequest,
  fetchPosts,
  updatePostRequest,
} from "../../../../api/feed";

interface IFeedProps {
  user: LoggedUser | null;
}

const fetchPostsQueryKey = "posts";

export interface PostsResponse {
  posts: PostApi[];
  count: number;
}

export const Feed = ({ user }: IFeedProps) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery({
      queryKey: [fetchPostsQueryKey],
      initialPageParam: 0,
      queryFn: ({ pageParam }) => fetchPosts(pageParam),

      getNextPageParam: (lastPage, pages) => {
        if (lastPage.count < 10) return undefined;
        return pages.length * 10;
      },
      refetchInterval: 2000,
    });

  const posts = data?.pages.flatMap((page) => page.posts);

  const handleReachBottom = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const { mutateAsync: createPost } = useMutation({
    mutationFn: createPostRequest,
    onSuccess: () => {
      refetch();
      Notification.success({
        content: "Post created successfully!",
      });
    },
    onError: () => {
      Notification.error({
        content: "Error creating post!",
      });
    },
  });

  const { mutateAsync: deletePost } = useMutation({
    mutationFn: deletePostRequest,
    onSuccess: () => {
      refetch();
      Notification.success({
        content: "Post deleted successfully!",
      });
    },
    onError: () => {
      Notification.error({
        content: "Error deleting post!",
      });
    },
  });

  const { mutateAsync: updatePost } = useMutation({
    mutationFn: updatePostRequest,
    onSuccess: () => {
      refetch();
      Notification.success({
        content: "Post updated successfully!",
      });
    },
    onError: () => {
      Notification.error({
        content: "Error updating post!",
      });
    },
  });

  const { mutateAsync: likePost } = useMutation({
    mutationFn: updatePostRequest,
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      Notification.error({
        content: "Error liking post!",
      });
    },
  });

  const handleCreatePost = async (newPost: PostType) => {
    await createPost(newPost);
  };

  const handleUpdatePost = (postId: number, updatedPost: PostApi) => {
    updatePost([postId, updatedPost]);
  };

  const handleDeletePost = (postId: number) => {
    deletePost(postId);
  };

  const getParsedArray = (array: string | CommentType[] | number[]) => {
    if (typeof array === "string") {
      const parsedArray = JSON.parse(array);
      return parsedArray;
    }
    return [];
  };

  const handleLike = (post: PostApi, userId: number) => {
    const likes = getParsedArray(post.likes);
    const comments = getParsedArray(post.comments);

    const updatedLikes = likes.includes(userId)
      ? likes.filter((id: number) => id !== userId)
      : [...likes, userId];

    const updatedPost = { ...post, comments: comments, likes: updatedLikes };

    likePost([post.id, updatedPost]);
  };

  return (
    <Card className="own-card" style={{ marginTop: 20 }}>
      <div className="card-header">
        <Typography.Title heading={3} className="card-header__title">
          Feed
        </Typography.Title>
        <CreatePostModal user={user} onCreatePost={handleCreatePost} />
      </div>
      <List
        className="posts-list"
        scrollLoading={hasNextPage && <Spin loading={isFetchingNextPage} />}
        onReachBottom={handleReachBottom}
        dataSource={posts}
        noDataElement={
          <span
            className="posts-list__no-data"
            style={{
              display: "flex",
              justifySelf: "center",
              padding: 280,
            }}
          >
            No data
          </span>
        }
        render={(item) => {
          if (user)
            return (
              <Post
                key={item.id}
                post={item}
                currentUser={user}
                refetch={refetch}
                onDeletePost={handleDeletePost}
                onLike={handleLike}
                onUpdatePost={handleUpdatePost}
              />
            );
        }}
      />
    </Card>
  );
};
