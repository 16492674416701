import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoggedUser } from "../models/Person";

interface UserState {
  user: LoggedUser | null;
}

const initialState: UserState = {
  user: JSON.parse(localStorage.getItem("user") || "null"),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<LoggedUser>) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    clearUser: (state) => {
      state.user = null;
      localStorage.clear();
    },
    setNewAvatar: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.avatar_url = action.payload;
        localStorage.setItem("user", JSON.stringify(state.user));
      }
    },
  },
});

export const { setUser, setNewAvatar, clearUser } = userSlice.actions;
export default userSlice.reducer;
