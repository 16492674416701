import { Button } from "@arco-design/web-react";
import {getAuth, OAuthProvider, signInWithPopup} from "firebase/auth";
import { endpoints } from "../api/endpoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { Message } from "@arco-design/web-react";
import { WindowsOutlined } from "@ant-design/icons"

export const MicrosoftRegisterButton = ({ isMentor }: { isMentor: boolean }) => {

    const navigate = useNavigate();

    const handleMicrosoftRegister = async () => {
        const auth = getAuth();
        const provider = new OAuthProvider("microsoft.com");

        try {
            const result = await signInWithPopup(auth, provider);
            const firebaseUser = result.user;
            const idToken = await firebaseUser.getIdToken();

            const response = await axios.post(
                endpoints.signup,
                {
                    name: firebaseUser.displayName || firebaseUser.email,
                    email: firebaseUser.email,
                    is_mentor: isMentor,
                    is_confirmed: true,
                },
                {
                    headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json" },
                }
            );

            Message.success({content: "You have successfully registered!", closable: true, duration: 3000});
            navigate(routes.profile);
            window.location.reload()
        } catch (error) {
            if (error instanceof Error && error.message === "EMAIL_EXISTS") {
                console.error("Outlook error - email already in use:", error);
                Message.error("This email is already registered. Please use a different email.");
            } else {
                console.error("Outlook registration failed:", error);
                Message.error("Error during registration. Please try again.");
            }
        }
    };

    return (
        <Button
            onClick={handleMicrosoftRegister}
            type="primary"
            shape="round"
            icon={<WindowsOutlined />}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 8, width: "100%" }}
        >
            Sign up with Outlook
        </Button>
    );
};
