import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { store } from "./store/store";
import { clearUser } from "./store/userSlice";
import { routes } from "./routes/routes";
import {endpoints} from "./api/endpoints";

export let cachedToken: string | null = null;

export const setupAxios = () => {
    axios.interceptors.request.use(
        async function (config) {
            config.withCredentials = true;
            const auth = getAuth();

            if (cachedToken) {
                config.headers.Authorization = `Bearer ${cachedToken}`;
                return config;
            }

            return new Promise((resolve) => {
                onAuthStateChanged(auth, async (user) => {
                    if (user) {
                        try {
                            cachedToken = await user.getIdToken();
                            config.headers.Authorization = `Bearer ${cachedToken}`;
                        } catch (error) {
                            console.error("Fail getting token from Firebase", error);
                        }
                    }
                    resolve(config);
                });
            });
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (response) => response, // Если нет ошибки, просто возвращаем ответ
        (error) => {
            const requestUrl = error.response?.config?.url;
            if (error.response?.status === 401) {
                if (requestUrl?.includes(endpoints.whoami)) {
                    return Promise.reject(error);
                }
                store.dispatch(clearUser());

                window.location.href = routes.LOGIN

                return Promise.reject("Unauthorized: Redirected to Login");
            }

            return Promise.reject(error);
        }
    );
};
