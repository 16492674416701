import {
  Button,
  Divider,
  Form,
  Input,
  Message,
  Typography,
} from "@arco-design/web-react";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { Grid } from "@arco-design/web-react";
import { Logo } from "../components/Logo";
import axios from "axios";
import { endpoints } from "../api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { clearUser, setUser } from "../store/userSlice";
import {
  boxBackgroundColor,
  buttonColor,
  buttonTextColor,
  colorAccent1,
  headerTextColor,
} from "../utils/cssVariables";
import { RootState } from "../store/store";
import { useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { GoogleAuthButton } from "../components/GoogleAuthButton";
import { AppleAuthButton } from "../components/AppleAuthButton";
import { MicrosoftLoginButton } from "../components/MicrosoftLoginButton";

const Row = Grid.Row;
const Col = Grid.Col;
const FormItem = Form.Item;

export const Login = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!!user) {
      const defaultPage = user.isAdmin ? routes.ADMIN_DASHBOARD : routes.DASHBOARD;
      navigate(defaultPage);
    }
  }, [navigate, user]);

  const onSubmit = async () => {
    if (form) {
      form.validate().then(async () => {
        const email = form.getFieldValue("email");
        const password = form.getFieldValue("password");
        const auth = getAuth();
        try {
          dispatch(clearUser());

          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          const firebaseUser = userCredential.user;
          const idToken = await firebaseUser.getIdToken()
          const response = await axios.get(endpoints.whoami, {
            headers: { Authorization: `Bearer ${idToken}` },
          });

          dispatch(setUser({ ...response.data }));
          navigate(routes.DASHBOARD)
          window.location.reload();
        } catch (error) {
          console.error("Login failed:", error);
          Message.error("Incorrect email or password. Please check your credentials and try again or reset your password.");
          localStorage.removeItem("user");
        }
      });
    }
  };

  return (
    <div
      style={{
        justifyContent: "center",
        backgroundColor: boxBackgroundColor,
        maxWidth: 480,
        width: "calc(100% - 40px)",
        margin: "0 auto",
        padding: "20px",
        borderRadius: 8,
      }}
    >
      <Logo
        size={350}
        style={{
          marginTop: 10,
          maxWidth: "100%",
        }}
      />
      <Form onSubmit={onSubmit} form={form}>
        <Row justify="center">
          <div
            style={{
              padding: "20px",
              width: "calc(100% - 40px)",
              maxWidth: 350,
            }}
          >
            <div style={{ display: "grid", marginTop: 10 }}>
              <Typography.Text
                style={{
                  fontSize: 32,
                  fontWeight: 500,
                  color: headerTextColor,
                }}
                type="success"
                className={"alice-regular"}
              >
                Already a user?
              </Typography.Text>
              <Typography.Text
                style={{
                  fontSize: 24,
                  fontWeight: 500,
                  lineHeight: 1,
                  color: headerTextColor,
                }}
                type="success"
                className={"alice-regular"}
              >
                Log in below:
              </Typography.Text>
            </div>
            <div style={{ marginTop: 40 }}>
              <FormItem
                id="email"
                field="email"
                layout="vertical"
                label="Email"
                rules={[{ required: true, message: "Please enter the user's email address." }, {
                  validator: (v, cb) => {
                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    if (!v || v.trim().length === 0) {
                      cb("Please enter the user's email address.");
                    } else if (v.length > 100) {
                      cb("Email address is too long.");
                    } else if (!emailRegex.test(v)) {
                      cb("Please enter a valid email address.");
                    } else {
                      cb();
                    }
                  },
                }]}
              >
                <Input placeholder="Enter your email" />
              </FormItem>
              <FormItem
                id="password"
                field="password"
                layout="vertical"
                label="Password"
                rules={[{ required: true, message: "Please enter your password." }, {
                  minLength: 6,
                  message: "Password must be at least 6 characters long.",
                }, {
                  validator: (v, cb) => {
                    if (!v || v.trim().length === 0) {
                      cb("Please enter a valid password. It cannot be empty or contain only spaces.");
                    } else {
                      cb();
                    }
                  },
                }]}
              >
                <Input.Password
                  type="password"
                  placeholder="Enter your password"
                />
              </FormItem>
              <Button
                htmlType="submit"
                style={{
                  color: buttonTextColor,
                  backgroundColor: buttonColor,
                  width: "100%",
                }}
                size={"large"}
                shape={"round"}
              >
                Log in
              </Button>
            </div>
            <Divider orientation="center">or</Divider>
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <GoogleAuthButton />
            </div>
            {/* <div style={{textAlign: "center", marginTop: 20}}>
                    <AppleAuthButton/>
                </div> */}
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <MicrosoftLoginButton />
            </div>
          </div>
        </Row>
      </Form>

      <div style={{ textAlign: "center", marginTop: 40 }}>
        <Typography.Paragraph>
          <div style={{ display: "grid", marginTop: 10 }}>
            <Typography.Text
              style={{
                fontSize: 32,
                fontWeight: 500,
                lineHeight: 1,
                color: headerTextColor,
              }}
              type="success"
              className={"alice-regular"}
            >
              Don't have an account?
            </Typography.Text>
            <Typography.Text
              style={{
                fontSize: 24,
                fontWeight: 500,
                lineHeight: 1,
                color: headerTextColor,
              }}
              type="success"
              className={"alice-regular"}
            >
              Register below:
            </Typography.Text>
            <NavLink to={routes.REGISTER}>
              <Button
                htmlType="submit"
                style={{
                  marginTop: 24,
                  color: buttonTextColor,
                  backgroundColor: buttonColor,
                  maxWidth: 350,
                  width: "100%",
                }}
                size={"large"}
                shape={"round"}
              >
                Register
              </Button>
            </NavLink>
          </div>
          <br />
          Forgot your password?
          <NavLink to={routes.RESETPASSWORD}>
            <Button
              style={{ padding: "0 0 0 4px" }}
              type="text"
              status="success"
            >
              <span style={{ color: colorAccent1 }}>Reset password</span>
            </Button>
          </NavLink>
        </Typography.Paragraph>
      </div>
    </div>
  );
};
