import { Member, User } from "@sendbird/chat/lib/__definition";
import { Avatar } from "@arco-design/web-react";
import React from "react";

type Props = {
  user: User | Member | null;
  size: number;
}

export function ConversationAvatar({ user, size }: Props) {
  if (!user) {
    return <Avatar size={size}>?</Avatar>;
  }

  return (
    <Avatar size={size}>
      {user.plainProfileUrl ? (
        <img
          alt={user.nickname}
          src={user.plainProfileUrl}
        />
      ) : (
        user.nickname[0]?.toUpperCase()
      )}
    </Avatar>
  );
}