import './Meetings.css';
import { useEffect, useState } from "react";
import { AdminMeeting } from "../../../models/Meeting";
import { MeetingCard } from "../../../components/MeetingCard/MeetingCard";
import axios, { AxiosResponse } from "axios";
import { endpoints } from "../../../api/endpoints";
import { Message } from "@arco-design/web-react";

type MeetingsByDays = Record<string, AdminMeeting[]>

export function Meetings() {
  const [meetingsList, setMeetingsList] = useState<AdminMeeting[]>([]);
  const [meetings, setMeetings] = useState<MeetingsByDays>({});
  const [meetingDays, setMeetingDays] = useState<string[]>([]);

  const loadMeetings = () => {
    axios
      .get(endpoints.admin.getMeetings)
      .then((response: AxiosResponse<AdminMeeting[]>) => {
        setMeetingsList(response.data);
      })
      .catch(() => {
        Message.error("Something went wrong. Please try again later.");
      });
  }

  useEffect(() => {
    const sortedList = meetingsList.sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());

    const meetingsByDays = sortedList.reduce((acc, meeting) => {
      const date = meeting.date;
      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(meeting);
      return acc;
    }, {} as MeetingsByDays);

    setMeetings(meetingsByDays);
    setMeetingDays(Object.keys(meetingsByDays));
  }, [meetingsList]);

  useEffect(() => {
    loadMeetings();
  }, []);


  const handleDeleteMeeting = (id: number) => {
    axios
      .delete(`${endpoints.admin.deleteMeeting}/${id}`)
      .then(() => {
        const updatedMeeting = meetingsList.filter((meeting) => meeting.id !== id);
        setMeetingsList(updatedMeeting);
        Message.success({content: "Meeting deleted successfully", closable: true, duration: 3000});
      })
      .catch(() => {
        Message.error("Something went wrong. Please try again later.");
      });
  }

  const handleUpdateMeeting = (id: number, day: string, time: number) => {
    axios
      .post(`${endpoints.admin.updateMeeting}/${id}`, { date: day, time })
      .then((response: AxiosResponse<AdminMeeting>) => {
        const updatedMeeting: AdminMeeting[] = meetingsList.map((meeting) => {
          if (meeting.id === response.data.id) {
            return response.data;
          }
          return meeting;
        });

        setMeetingsList(updatedMeeting);
        Message.success({content: "Meeting updated successfully", closable: true, duration: 3000});
      })
      .catch(() => {
        Message.error("Something went wrong. Please try again later.");
      });
  }

  return (
    <div className="meetings">
      <h1 className="meetings__title">Meetings list</h1>
      <div className="meetings__content">
        {meetingDays.map((day) => {
          return (
            <div key={day} className="meetings__day">
              <h2 className="meetings__day-title">{day}</h2>
              <div className="meetings__day-content">
                {meetings[day].map((meeting) => (
                  <div key={meeting.id} className="meetings__day-meeting">
                    <MeetingCard
                      meeting={meeting}
                      onUpdateMeeting={(date, time) => handleUpdateMeeting(meeting.id, date, time)}
                      onDeleteMeeting={() => handleDeleteMeeting(meeting.id)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}