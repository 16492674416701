import { Button, Input } from "@arco-design/web-react";

import {
  MAX_COMMENT_LENGTH,
  SYMBOLS_COUNTER_NEEDED_LENGTH,
} from "../../constants";
import { PostApi } from "../../types/types";

import { buttonColor } from "../../../../../../utils/cssVariables";

interface ICommentInputProps {
  comment: string;
  post: PostApi;
  isMutating: boolean;
  onSetNewComment: (comment: string) => void;
  onAddComment: (post: PostApi) => void;
}

const CommentInput = ({
  comment,
  post,
  isMutating,
  onAddComment,
  onSetNewComment,
}: ICommentInputProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        gap: 16,
        marginTop: 6,
      }}
    >
      <div style={{ width: "100%" }}>
        <Input.TextArea
          value={comment}
          onChange={(value) => onSetNewComment(value)}
          placeholder="Write a comment"
          autoSize
          maxLength={MAX_COMMENT_LENGTH}
        />
        {comment?.length > SYMBOLS_COUNTER_NEEDED_LENGTH.COMMENT && (
          <div style={{ textAlign: "right" }}>
            {comment?.length ?? 0} / {MAX_COMMENT_LENGTH}
          </div>
        )}
      </div>

      <Button
        onClick={() => onAddComment(post)}
        style={{
          backgroundColor: buttonColor,
          marginBottom:
            comment?.length > SYMBOLS_COUNTER_NEEDED_LENGTH.COMMENT ? 22 : 0,
        }}
        size="small"
        type="primary"
        disabled={isMutating}
      >
        Send
      </Button>
    </div>
  );
};

export default CommentInput;
