import axios from "axios";

import { endpoints } from "../../../api/endpoints";

export const downloadCSVReport = async () => {
  try {
    const response = await axios.get(endpoints.admin.getCSVReport, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file", error);
  }
};
