import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useEffect, useState } from "react";
import { User } from "../../../models/Person";
import axios from "axios";
import { endpoints } from "../../../api/endpoints";
import { setSelectedUser, setUsersList } from "../../../store/adminSlice";
import { AutoComplete, Button, Card, Message, Spin } from "@arco-design/web-react";
import { UserCard } from "../../../components/UserCard/UserCard";
import { getCurrentDate } from "../../../utils/functions";
import './UserBooking.css';
import { IconLeft } from "@arco-design/web-react/icon";
import { BookingForm } from "../../../components/BookingForm/BookingForm";
import { routes } from "../../../routes/routes";
import { SuccessScreen } from "../../../components/SuccessScreen/SuccessScreen";

const { Option } = AutoComplete;

const getFilteredProfiles = (list: User[], searchQuery: string) => {
  return list.filter((profile) => {
    const isNameMatch = searchQuery ? profile.name.toLowerCase().includes(searchQuery.toLowerCase()) : true;
    const isIdMatch = searchQuery ? profile.id.toString().includes(searchQuery.toLowerCase()) : true;
    return isNameMatch || isIdMatch;
  });
}

export function UserBooking() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const usersList = useSelector((state: RootState) => state.admin.usersList);
  const selectedUser = useSelector((state: RootState) => state.admin.selectedUser);
  const hasUserData = selectedUser?.id.toString() === id;

  const [isLoading, setIsLoading] = useState<boolean>(!hasUserData);
  const [user, setUser] = useState<User | null>(hasUserData ? selectedUser : null);
  const [selectedDate, setSelectedDate] = useState<string>(getCurrentDate());
  const [selectedTime, setSelectedTime] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [profiles, setProfiles] = useState<User[]>(usersList);
  const [filteredProfiles, setFilteredProfiles] = useState<User[]>(getFilteredProfiles(usersList, searchQuery));
  const [selectedRespondent, setSelectedRespondent] = useState<User | null>(null);

  const [isMeetingBooked, setIsMeetingBooked] = useState<boolean>(false);

  const getProfile = () => {
    axios
      .get(`${endpoints.admin.getUser}/${id}`)
      .then((response) => {
        setUser(response.data);
        dispatch(
          setSelectedUser(response.data)
        )
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const getProfiles = () => {
    axios
      .get(`${endpoints.admin.getUsers}`)
      .then((response) => {
        setProfiles(response.data);
        setFilteredProfiles(getFilteredProfiles(response.data, searchQuery));
        dispatch(
          setUsersList(response.data)
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getProfile();
    getProfiles();
  }, []);

  useEffect(() => {
    setFilteredProfiles(getFilteredProfiles(profiles, searchQuery))
  }, [searchQuery]);

  const bookMeeting = () => {
    if (!selectedDate) {
      Message.error("Please select a day");
      return;
    }

    if (!selectedTime) {
      Message.error("Please select a time");
      return;
    }

    if (!selectedRespondent) {
      Message.error("Please select a respondent");
      return;
    }

    axios
      .post(`${endpoints.admin.bookMeeting}`, {
        mentor_id: user?.id,
        mentee_id: selectedRespondent.id,
        date: selectedDate,
        hour: selectedTime,
        timezone: timezone,
      })
      .then(() => {
        setIsMeetingBooked(true);
      })
      .catch(() => {
        Message.error("Error booking meeting");
      });
  };

  return (
    <div className="user-booking">
      {!isMeetingBooked && (
        <div className="user-booking__header">
          <Button
            onClick={() => {
              navigate(-1);
            }}
            className="user-booking__icon-button"
          >
            <IconLeft fontSize={24}/>
          </Button>
          <h1 className="user-booking__title">User Profile</h1>
        </div>
      )}

      {!user && isLoading && (
        <div className="user-booking__loader-wrapper">
          <Spin size={40}/>
        </div>
      )}
      {!isLoading && user && !isMeetingBooked && (
        <div className="user-booking__content">
          <UserCard user={user} hideActions/>
          <Card className="user-booking__card" bordered={false}>
            <div className="user-booking__card-header">Choose a session partner</div>
            <div className="user-booking__autocomplete-wrapper">
              <div className="user-booking__label">Name / ID</div>
              <AutoComplete
                placeholder='Search'
                onSearch={setSearchQuery}
                dropdownRender={() => {
                  return (
                    <div className="user-booking__autocomplete-dropdown">
                      {
                        filteredProfiles.map((profile) => (
                          <button onClick={() => setSelectedRespondent(profile)} key={profile.id}
                                  className="user-booking__autocomplete-option">
                            <UserCard user={profile} hideActions withShadow/>
                          </button>
                        ))
                      }
                    </div>
                  )
                }}
              >
                {
                  filteredProfiles.map((profile) => (
                    <Option className="user-booking__autocomplete-option" key={profile.id}
                            value={`${profile.name} ${profile.id}`}>
                      {profile.name}
                    </Option>
                  ))
                }
              </AutoComplete>
            </div>
            {selectedRespondent && (
              <UserCard user={selectedRespondent} hideActions/>
            )}
          </Card>
          <Card className="user-booking__card" bordered={false}>
            <BookingForm
              userId={id}
              day={selectedDate}
              time={selectedTime}
              onDayChange={setSelectedDate}
              onTimeChange={setSelectedTime}
            />
          </Card>

          <div className="user-booking__footer">
            <Button
              className="user-booking__footer-button user-booking__secondary-button"
              size="large"
              shape={"round"}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
            <Button
              className="user-booking__footer-button user-booking__primary-button"
              size="large"
              shape={"round"}
              onClick={bookMeeting}
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {isMeetingBooked && (
        <SuccessScreen
          title="You have successfully booked an meeting"
          buttonText="Back to Profile"
          onButtonClick={() => {
            user && navigate(routes.ADMIN_USER.replace(":id", user?.id.toString()));
          }}
        />
      )}
    </div>
  );
}