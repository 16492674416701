import {
  Form,
  Grid,
  Input,
  Button,
  Message,
  Tooltip,
  Typography,
  VerificationCode,
} from "@arco-design/web-react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { useEffect, useState } from "react";
import { Logo } from "../components/Logo";
import axios from "axios";
import { endpoints } from "../api/endpoints";
import {
  boxBackgroundColor,
  buttonColor,
  buttonTextColor,
  colorAccent1,
} from "../utils/cssVariables";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const Row = Grid.Row;
const FormItem = Form.Item;
const PasswordComplexityTooltip = (
  <div>
    <Typography.Paragraph
        style={{ fontSize: 12, color: "white", marginBottom: 0, whiteSpace: "pre-wrap", wordBreak: "break-word" }}
        spacing="close"
    >
      Password must be at least 8 characters long and must contain at least:
      <ul>
        <li>one lowercase letter,</li>
        <li>one uppercase letter,</li>
        <li>one digit,</li>
        <li>and one special character.</li>
      </ul>
    </Typography.Paragraph>
  </div>
)

export const ResetPassword = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    const otp = params.get("otp");
    return { email, otp };
  };

  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  useEffect(() => {
    if (!!user) {
      const defaultPage = user.isAdmin ? routes.ADMIN_DASHBOARD : routes.DASHBOARD;
      navigate(defaultPage);
    }
  }, [navigate, user]);

  const sendEmailWithOtpCode = (formEmail: string | undefined = undefined) => {
    axios
      .post(endpoints.recoverAccount, {
        "email": form.getFieldValue("email"),
      })
      .then(() => {
        if (!formEmail) {
          Message.success({content: "A link to reset your password has been sent to your email.", closable: true, duration: 3000});
        }
      })
      .catch((error) => {
        console.error(error);
        Message.error("Something went wrong. Please try again later.");
      })
      .finally(() => {});
  }

  const onSubmit = async () => {
    if (!isEmailSent) {
      setIsEmailSent(true);
      form.validate().then(sendEmailWithOtpCode);
    }
  };

  useEffect(() => {
    const { email, otp } = getQueryParams();

    if (email && otp) {
      setIsEmailSent(true);
      form.setFieldValue("otp", otp);
      form.setFieldValue("email", email);
    }
  }, []);  

  return (
      <div
        style={{
          justifyContent: "center",
          backgroundColor: boxBackgroundColor,
          maxWidth: 480,
          width: "calc(100% - 40px)",
          margin: "0 auto",
          padding: "20px",
          borderRadius: 8,
        }}
      >
        <Logo
          size={350}
          style={{
            marginTop: 10,
            maxWidth: "100%",
          }}
        />
        <Form onSubmit={onSubmit} form={form}>
          <Row justify="center">
            <div
              style={{
                padding: "20px",
                width: "calc(100% - 40px)",
                maxWidth: 350,
              }}
            >
              <div style={{marginTop: 10}}>
                <Typography.Text
                  style={{fontSize: 20, color: colorAccent1, wordBreak: "break-word"}}
                  type="success"
                  className={"alice-regular"}
                >
                  {!isEmailSent ? "Trouble logging in?" : "Reset password"}
                </Typography.Text>
              </div>
              <div style={{marginTop: 30}}>
                {!isEmailSent ? (
                  <Typography.Paragraph style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                    Enter your account's email and we'll send you an email to reset the password.
                  </Typography.Paragraph>
                ) : (
                  <Typography.Paragraph style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                    If an account with the provided email exists, we’ve sent you a password reset link.
                    Please check your email and follow the link to set a new password. 
                    <br/>
                  </Typography.Paragraph>
                )}
              </div>
              <div style={{marginTop: 20}}>
                {<FormItem
                  layout="vertical"
                  label="Email"
                  field="email"
                  disabled={isEmailSent}
                  rules={!isEmailSent ? [
                    {
                      validator: (v, cb) => {
                        return !v ? cb("Email is required")
                          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) ? cb("Invalid email address")
                          : cb();
                      }
                    }
                  ] : []}
                  validateTrigger="onBlur"
                >
                  <Input
                    type="email"
                    placeholder="name@example.com"
                  />
                </FormItem>}
                {!isEmailSent && <Button
                    htmlType="submit"
                    style={{
                      color: buttonTextColor,
                      backgroundColor: buttonColor,
                      width: "100%",
                      marginTop: 20,
                    }}
                    size={"large"}
                    shape={"round"}
                  >
                    Send email
                  </Button>}
                {isEmailSent && <>
                  <Typography.Paragraph style={{ marginTop: 30, whiteSpace: "pre-wrap", wordBreak: "break-word" }}>Once your password has been reset, return to the login page and sign in with your new credentials.</Typography.Paragraph>

                    <FormItem layout="vertical" style={{ marginTop: 10, marginBottom: 0 }}>
                      <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 8,}}>
                        <Button
                          onClick={() => {
                            form.resetFields();
                            setIsEmailSent(false);
                          }}
                          size={"large"}
                          shape={"round"}
                        >
                          Use a different email
                        </Button>
                        <Button
                          htmlType="submit"
                          style={{
                            color: buttonTextColor,
                            backgroundColor: buttonColor
                          }}
                          size={"large"}
                          shape={"round"}
                          onClick={() => navigate(routes.LOGIN)}
                        >
                          Back to login
                        </Button>
                      </Row>
                    </FormItem>
                </>}
              </div>
            </div>
          </Row>
        </Form>

        <div style={{textAlign: "center", marginTop: 20}}>
          <Typography.Paragraph>
            Remember your credentials?
            <NavLink to={routes.LOGIN}>
              <Button
                  style={{padding: "0 0 0 4px"}}
                  type="text"
                  status="success"
              >
              <span style={{ color: colorAccent1 }}>Back to login</span>
            </Button>
          </NavLink>
        </Typography.Paragraph>
      </div>
    </div>
  );
};
