import {
  Card,
  Avatar,
  Space,
  Button,
  Typography,
  Message, Popconfirm,
} from "@arco-design/web-react";
import {IconQuestionCircle, IconThumbDown} from "@arco-design/web-react/icon";
import {ReactNode, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {MeetingCardProps} from "../models/Person";
import {routes} from "../routes/routes";
import {Meeting} from "../models/Meeting";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import axios from "axios";
import {endpoints} from "../api/endpoints";
import {buttonColor, buttonTextColor} from "../utils/cssVariables";

const {Meta} = Card;

type Props = {
  meeting: Meeting,
  onCancelMeeting: () => void;
}

export const MeetingCard = ({meeting, onCancelMeeting}: Props) => {
  const user = useSelector((state: RootState) => state.user.user);

  const {mentor_user_id, mentee_user_id, mentee_name, mentor_name, id, display_time, mentee_avatar_url, mentor_avatar_url} = meeting;

  const otherPersonId: number = mentor_user_id === user?.id ? mentee_user_id || 0 : mentor_user_id || 0;
  const otherPersonName: string = mentor_user_id === user?.id ? mentee_name : mentor_name;
  const otherPersonAvatar: string = mentor_user_id === user?.id ? mentee_avatar_url : mentor_avatar_url;

  return (
    <Card
      className="card-with-icon-hover"
      style={{
        padding: 10,
        minHeight: 306,
      }}
      cover={
        <div>
          <Avatar size={160}>
            <img
              style={{width: "100%"}}
              alt="avatar"
              src={otherPersonAvatar}
            />
          </Avatar>
        </div>
      }
    >
      <Typography.Title heading={4} style={{wordBreak: "break-word", margin: "5px 0"}}>
        Meeting with {otherPersonName}
      </Typography.Title>
      <Typography.Paragraph
        style={{wordBreak: "break-word", margin: "5px 0"}}>{display_time}</Typography.Paragraph>
      <Space style={{marginTop: 10}}>
        <Popconfirm
          title={"Are you sure you want to cancel this meeting?"}
          icon={<IconQuestionCircle/>}
          onOk={() => {
            axios
              .post(`${endpoints.cancelMeeting}`, {
                meeting_id: id,
              })
              .then((response) => {
                onCancelMeeting();
                Message.success({content: "Meeting canceled successfully", closable: true, duration: 3000});
              })
              .catch((error) => {
                console.error(error);
                Message.error("Could not cancel");
              });
          }}
          okText={"Yes, cancel"}
          cancelText={"No, keep"}
          okButtonProps={{
            size: "large",
            style: {
              color: buttonTextColor,
              backgroundColor: buttonColor,
            },
          }}
          cancelButtonProps={{
            size: "large",
            style: {
              color: buttonTextColor,
              backgroundColor: buttonColor,
            },
          }}
        >
          <Button
            size={"large"}
            shape={"round"}
          >
            Cancel
          </Button>
        </Popconfirm>
        <NavLink to={`${routes.MENTOR.replace(":id", otherPersonId.toString())}`}>
          <Button
            style={{
              color: buttonTextColor,
              backgroundColor: buttonColor,
            }}
            size={"large"}
            shape={"round"}
          >
            View Profile
          </Button>
        </NavLink>
      </Space>
    </Card>
  );
};
