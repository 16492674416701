import { Button } from "@arco-design/web-react";
import { GoogleOutlined } from "@ant-design/icons";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setUser } from "../store/userSlice";
import { endpoints } from "../api/endpoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { Message } from "@arco-design/web-react";

export const GoogleRegisterButton = ({ isMentor }: { isMentor: boolean }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleGoogleRegister = async () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();

        try {
            const result = await signInWithPopup(auth, provider);
            const firebaseUser = result.user;
            const idToken = await firebaseUser.getIdToken();

            const response = await axios.post(
                endpoints.signup,
                {
                    name: firebaseUser.displayName || "New User",
                    email: firebaseUser.email,
                    is_mentor: isMentor,
                    is_confirmed: true,
                },
                {
                    headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json" },
                }
            );

            Message.success({content: "You have successfully registered!", closable: true, duration: 3000});
            navigate(routes.profile);
            window.location.reload()
        } catch (error) {
            console.error("Google registration failed:", error);
            Message.error("Error during registration. Please try again.");
        }
    };

    return (
        <Button
            onClick={handleGoogleRegister}
            type="primary"
            shape="round"
            icon={<GoogleOutlined />}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 8, width: "100%" }}
        >
            Sign up with Google
        </Button>
    );
};
