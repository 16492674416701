import { Button } from "@arco-design/web-react";
import { getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";
import { endpoints } from "../api/endpoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { Message } from "@arco-design/web-react";
import { WindowsOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux";
import { clearUser, setUser } from "../store/userSlice";

export const MicrosoftLoginButton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleMicrosoftLogin = async () => {
        const auth = getAuth();
        const provider = new OAuthProvider("microsoft.com");

        try {
            dispatch(clearUser());

            const result = await signInWithPopup(auth, provider);
            const firebaseUser = result.user;
            const idToken = await firebaseUser.getIdToken();
            const response = await axios.get(endpoints.whoami, {
                headers: { Authorization: `Bearer ${idToken}` },
            });

            dispatch(setUser({ ...response.data }));
            navigate(routes.DASHBOARD);
            window.location.reload();
        } catch (error) {
            Message.error("This account doesn't exist. Please use a different email or create new account.");
            console.error("Google login failed:", error);
        }
    };

    return (
        <Button
            onClick={handleMicrosoftLogin}
            type="primary"
            shape="round"
            icon={<WindowsOutlined />}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 8, width: "100%" }}
        >
            Sign in with Outlook
        </Button>
    );
};
