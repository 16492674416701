import { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Notification,
} from "@arco-design/web-react";

import {
  MAX_POST_LENGTH,
  MAX_TITLE_LENGTH,
  SYMBOLS_COUNTER_NEEDED_LENGTH,
} from "../constants";
import { Post } from "../types/types";

import {
  buttonColor,
  buttonTextColor,
} from "../../../../../utils/cssVariables";
import { LoggedUser } from "../../../../../models/Person";
import { endpoints } from "../../../../../api/endpoints";

interface ICreatePostModalProps {
  user: LoggedUser | null;
  onCreatePost: (newPost: Post) => void;
}

const CreatePostModal = ({ user, onCreatePost }: ICreatePostModalProps) => {
  const [visible, setVisible] = useState(false);

  const initialValues = { heading: "", content: "" };
  const [post, setPost] = useState(initialValues);

  const showModal = () => {
    setVisible(true);
  };

  const handleCreate = async () => {
    const { heading, content } = post;
    if (heading.trim() === "" || content.trim() === "") {
      Notification.error({
        content: "Please enter a title and content for your post",
      });
      return;
    }

    if (content.length > MAX_POST_LENGTH) {
      Notification.error({
        content: `The content of your post exceeds the ${MAX_POST_LENGTH} character limit. Please shorten it.`,
      });
      return;
    }

    if (heading.length > MAX_TITLE_LENGTH) {
      Notification.error({
        content: `The title of your post exceeds the ${MAX_TITLE_LENGTH} character limit. Please shorten it.`,
      });
      return;
    }



    if (user) {
      const newPost: Post = {
        heading,
        content,
        user_id: user.id,
        user_name: user.name,
        avatar_url: user.avatar_url,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        likes: [],
        comments: [],
      };
      onCreatePost(newPost);
      setVisible(false);
      setPost(initialValues);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setPost(initialValues);
  };

  return (
    <div>
      <Button
        style={{
          color: buttonTextColor,
          backgroundColor: buttonColor,
        }}
        size={"large"}
        onClick={showModal}
      >
        Create new post
      </Button>

      <Modal
        title="Create New Post"
        visible={visible}
        onOk={handleCreate}
        onCancel={handleCancel}
        okText="Create"
        cancelText="Cancel"
        className="feed-modal"
      >
        <Form layout="vertical">
          <Form.Item label="Title">
            <Input
              value={post.heading}
              onChange={(value) => setPost({ ...post, heading: value })}
              maxLength={MAX_TITLE_LENGTH}
              placeholder="Post title"
            />
            {post.heading.length > SYMBOLS_COUNTER_NEEDED_LENGTH.TITLE && (
              <div style={{ textAlign: "right" }}>
                {post.heading.length} / {MAX_TITLE_LENGTH}
              </div>
            )}
          </Form.Item>
          <Form.Item label="Content">
            <Input.TextArea
              value={post.content}
              onChange={(value: string) => setPost({ ...post, content: value })}
              rows={5}
              maxLength={MAX_POST_LENGTH}
              placeholder="Write your post here..."
            />
            {post.content.length > SYMBOLS_COUNTER_NEEDED_LENGTH.POST && (
              <div style={{ textAlign: "right" }}>
                {post.content.length} / {MAX_POST_LENGTH}
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CreatePostModal;
