import { Button, Radio } from "@arco-design/web-react";
import { buttonColor, buttonTextColor } from "../../../../utils/cssVariables";
import {GroupChannelList} from '@sendbird/uikit-react/GroupChannelList';
import React, { useEffect, useState } from "react";
import { useSendbirdStateContext } from "@sendbird/uikit-react";
import { User, GroupChannel } from "@sendbird/chat/lib/__definition";

import { IconPlus } from "@arco-design/web-react/icon";
import "./ConversationsList.css";
import { ConversationPreview } from "./ConversationPreview/ConversationPreview";
import {
  GroupChannelListItemBasicProps
} from "@sendbird/uikit-react/types/modules/GroupChannelList/components/GroupChannelListItem/GroupChannelListItemView";
import { StartConversationDialog } from "../StartConversationDialog/StartConversationDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../../routes/routes";
import { getUserFromConversation } from "../../utils/getUserFromConversation";
import { MemberWithMetaData } from "../../types";

type Props = {
  selectedConversation: GroupChannel | null;
  onConversationSelected: (channel: GroupChannel | null) => void;
}

const OPERATOR_ID = '627668';

export function ConversationsList({ selectedConversation, onConversationSelected } : Props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const RadioGroup = Radio.Group;
  const [showNewContactModal, setShowNewContactModal] = useState<boolean>(false);
  const [chatsFilter, setChatsFilter] = useState<string>('all');

  const { stores: { sdkStore: { sdk }, userStore } } = useSendbirdStateContext();

  useEffect(() => {
    if (sdk.groupChannel && state?.userId) {
      const params = {
        invitedUserIds: [state.userId],
        name: `${userStore.user.nickname} (${userStore.user.userId}) - ${state.name} (${state.userId})`,
        customType: state.isMentor ? 'withMentor' : 'withMentee',
        isDistinct: true,
        operatorUserIds: [OPERATOR_ID],
      };

      sdk.groupChannel.createChannel(params).then((channel: GroupChannel) => {
        onConversationSelected(channel);
        navigate(routes.MESSAGES, { state: null });
      });
    }
  }, [state?.userId, sdk.groupChannel])

  const chatsFilterOptions = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'withMentor',
      label: 'Mentors',
    },
    {
      value: 'withMentee',
      label: 'Mentees',
    },
  ];

  const handleCreateNewChannel = (user: User) => {
    const params = {
      invitedUserIds: [user.userId],
      name: `${userStore.user.nickname}(${userStore.user.userId}) - ${user.nickname}(${user.userId})`,
      isDistinct: true,
      operatorUserIds: [OPERATOR_ID],
    };

    sdk.groupChannel.createChannel(params).then((channel: GroupChannel) => {
      onConversationSelected(channel);
    });
  }

  const isMatchFilter = (respondent: MemberWithMetaData | null) => {
    if (chatsFilter === 'all' || !respondent) {
      return true;
    }

    if (chatsFilter === 'withMentor') {
      return respondent?.metaData?.isMentor === 'true';
    }

    if (chatsFilter === 'withMentee') {
      return respondent?.metaData?.isMentor === 'false'
    }

    return true;
  }

  return (
    <>
      <StartConversationDialog
        visible={showNewContactModal}
        onClose={() => setShowNewContactModal(false)}
        onMessagingStart={handleCreateNewChannel}
      />
      <GroupChannelList
        renderHeader={() => {
          return (
            <div className="conversations-list-header">
              <RadioGroup
                options={chatsFilterOptions}
                size='default'
                type='button'
                value={chatsFilter}
                onChange={setChatsFilter}
              />
              <Button
              style={{
                color: buttonTextColor,
                backgroundColor: buttonColor,
              }}
              className="add-contact-button"
              size="large"
              shape="round"
              onClick={() => setShowNewContactModal(true)}
            >
              <IconPlus fontSize={24} />
            </Button>
            </div>
          );

        }}
        allowProfileEdit={false}
        disableUserProfile
        onChannelCreated={() => {
        }}
        onChannelSelect={() => {}}
        renderChannelPreview={(channel: GroupChannelListItemBasicProps) => {
          const respondent = getUserFromConversation(channel.channel, userStore.user);

          if (!isMatchFilter(respondent)) {
            return <div />;
          }

          return (
            <ConversationPreview onClick={() => onConversationSelected(channel.channel)} conversation={channel} isSelected={channel.channel.url === selectedConversation?.url} />
          )
        }}
      />
    </>
  );
}