import {
    Form,
    Input,
    Button,
    Checkbox,
    Message,
    Typography,
    Link,
    Divider,
} from "@arco-design/web-react";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { ReactNode, useEffect, useState } from "react";
import { registerUser } from "../firebaseConfig";
import { Logo } from "../components/Logo";
import { Grid } from "@arco-design/web-react";
import axios from "axios";
import { endpoints } from "../api/endpoints";
import {
    boxBackgroundColor,
    buttonColor,
    buttonTextColor,
    colorAccent1,
} from "../utils/cssVariables";
import { RootState } from "../store/store";
import { GoogleRegisterButton } from "../components/GoogleRegisterButton";
import { AppleRegisterButton } from "../components/AppleRegisterButton"
import { IconEmail } from "@arco-design/web-react/icon";
import { useSelector } from "react-redux";
import { MicrosoftRegisterButton } from "../components/MicrosoftRegisterButton";

const Row = Grid.Row;
const FormItem = Form.Item;

const handleSignUpError = (error: unknown) => {
    if (typeof error === "object" && error !== null) {
        const err = error as Record<string, any>;

        // Check for backend error structure
        if (err.error?.code === 400 && err.error.message === "EMAIL_EXISTS") {
            Message.error("This email is already in use. Please use a different email.");
        } else if (err.error?.code === 500) {
            Message.error("Server error. Please try again later.");
        } else if (err.code) {
            // Firebase error handling
            switch (err.code) {
                case "auth/email-already-in-use":
                    Message.error("This email is already in use. Please use a different email.");
                    break;
                case "auth/invalid-email":
                    Message.error("Please enter a valid email address.");
                    break;
                case "auth/weak-password":
                    Message.error("Your password is too weak. Please choose a stronger password.");
                    break;
                default:
                    Message.error("An error occurred during registration. Please try again.");
            }
        } else {
            // General error
            Message.error("An unexpected error occurred. Please try again.");
        }
    } else {
        // Unknown error type
        Message.error("An unexpected error occurred. Please try again.");
    }
}

export const Register = () => {
    const user = useSelector((state: RootState) => state.user.user);

    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [isMentor, setIsMentor] = useState<boolean>(false);
    const [isMentee, setIsMentee] = useState<boolean>(false);

    const [isEmailVerificationSent, setEmailVerificationSent] = useState(false);

    useEffect(() => {
        if (!!user) {
            const defaultPage = user.isAdmin ? routes.ADMIN_DASHBOARD : routes.DASHBOARD;
            navigate(defaultPage);
        }
    }, [navigate, user]);

    const handleResendEmail = async () => {
        try {
            if (user) {
                await axios
                    .post(
                        endpoints.confirmEmail,
                        {
                            email: user.email,
                        },
                    )
                    .finally(() => {
                        setEmailVerificationSent(true);
                    });
                Message.success({ closable: true, content: "Confirmation email resent. Please check your inbox." });
            }
        } catch (error) {
            console.error(error);
            Message.error("Failed to resend confirmation email. Please try again.");
        }
    };

    const onSubmit = async () => {
        try {
            const values = await form.validate();
            const { name, email, password } = values;

            const userCredential = await registerUser(email, password);
            const idToken = await userCredential.getIdToken();

            await axios.post(
                endpoints.signup,
                {
                    name,
                    email,
                    is_mentor: isMentor,
                    is_confirmed: false,
                },
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                        "Content-Type": "application/json",
                    },
                }
            ).then(async (response) => {
                await axios
                    .post(
                        endpoints.confirmEmail,
                        {
                            email,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${idToken}`,
                                "Content-Type": "application/json",
                            },
                        })
            })
                .finally(() => {
                    setEmailVerificationSent(true);
                });
        } catch (error) {
            console.error(error);
            handleSignUpError(error);
        }
    };

    const onMentorPick = () => {
        setIsMentor(true);
    };

    const onMenteePick = () => {
        setIsMentee(true);
    };

    const onGoBack = () => {
        setIsMentor(false);
        setIsMentee(false);
    };

    const PageWrapper = ({ children }: { children: ReactNode }) => (
        <div
            style={{
                justifyContent: "center",
                backgroundColor: boxBackgroundColor,
                maxWidth: 480,
                width: "calc(100% - 40px)",
                margin: "0 auto",
                padding: "20px",
                borderRadius: 8,
            }}
        >
            <Logo
                size={350}
                style={{
                    marginTop: 10,
                    width: "100%",
                }}
            />
            {children}
        </div>
    )

    // useEffect(() => {

    // }, [isEmailVerificationSent])

    if (isEmailVerificationSent) {
        return (
            <PageWrapper>
                <Row
                    justify="center"
                    style={{
                        alignItems: "center",
                        gap: 12,
                        marginTop: 40,
                        marginBottom: 24,
                    }}
                >
                    <IconEmail style={{ fontSize: 32, stroke: colorAccent1 }} />
                    <Typography.Text
                        style={{
                            fontSize: 20,
                            color: colorAccent1,
                            wordBreak: "break-word",
                        }}
                        type="success"
                        className={"alice-regular"}
                    >
                        Email Confirmation
                    </Typography.Text>
                </Row>

                <Typography.Text style={{ wordBreak: "break-word" }}>
                    A confirmation email has been sent to your email address
                    {user ? (
                        <Typography.Text
                            style={{ wordBreak: "break-word", color: colorAccent1 }}
                        >{` ${user?.email}. `}</Typography.Text>
                    ) : (
                        ". "
                    )}
                    Please check your inbox and follow the instructions to verify your
                    account.
                </Typography.Text>
                <Row
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 24,
                        marginTop: 40,
                    }}
                >
                    <Button
                        onClick={() => {
                            navigate(routes.LOGIN)
                        }}
                        size={"large"}
                        shape={"round"}
                    >
                        Back to Login
                    </Button>
                    <Button
                        htmlType="submit"
                        style={{
                            color: buttonTextColor,
                            backgroundColor: buttonColor,
                        }}
                        size={"large"}
                        shape={"round"}
                        onClick={() => handleResendEmail()}
                    >
                        Resend Confirmation Email
                    </Button>
                </Row>
            </PageWrapper>
        );
    }

    return isMentor || isMentee ? (
        <div
            style={{
                justifyContent: "center",
                backgroundColor: boxBackgroundColor,
                maxWidth: 480,
                width: "calc(100% - 40px)",
                margin: "0 auto",
                padding: "20px",
                borderRadius: 8,
            }}
        >
            <Logo
                size={350}
                style={{
                    marginTop: 10,
                    width: "100%",
                }}
            />
            <div style={{ marginBottom: 16 }}>
                <Typography.Title heading={2}>
                    Register as a {isMentee ? "Mentee" : "Mentor"}
                </Typography.Title>

                <Form
                    form={form}
                    autoComplete="off"
                    size={"default"}
                    scrollToFirstError
                    onSubmit={onSubmit}
                >
                    <div style={{ marginTop: 20 }}>
                        <GoogleRegisterButton isMentor={isMentor} />
                    </div>

                    {/* <div style={{marginTop: 20}}>
                    <AppleRegisterButton isMentor={isMentor}/>
                </div> */}
                    <div style={{ marginTop: 20 }}>
                        <MicrosoftRegisterButton isMentor={isMentor} />
                    </div>
                    <Divider orientation="center">or</Divider>
                    <FormItem
                        layout="vertical"
                        label="Full name"
                        field="name"
                        rules={[{
                            required: true,
                            message: "Please enter your full name.",
                            validator: (v, cb) => !v || v.trim().length === 0 ? cb("Please enter your full name.") : cb()
                        }]}
                    >
                        <Input
                            placeholder="Please enter your full name"
                            autoComplete={"off"}
                        />
                    </FormItem>
                    <FormItem
                        layout="vertical"
                        label="Email"
                        field="email"
                        rules={[{ required: true, message: "Please enter the user's email address." }, {
                            validator: (v, cb) => {
                                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                if (!v || v.trim().length === 0) {
                                    cb("Please enter the user's email address.");
                                } else if (v.length > 100) {
                                    cb("Email address is too long.");
                                } else if (!emailRegex.test(v)) {
                                    cb("Please enter a valid email address.");
                                } else {
                                    cb();
                                }
                            },
                        }]}
                    >
                        <Input
                            type="email"
                            placeholder="Please enter email"
                            autoComplete={"off"}
                        />
                    </FormItem>
                    <FormItem
                        layout="vertical"
                        label="Password"
                        field="password"
                        rules={[{ required: true, message: "Please enter your password." }, {
                            minLength: 6,
                            message: "Password must be at least 6 characters long.",
                        },
                        {
                            validator: (v, cb) => {
                                if (!v || v.trim().length === 0) {
                                    cb("Please enter a valid password. It cannot be empty or contain only spaces.");
                                } else {
                                    cb();
                                }
                            },
                        }]}
                    >
                        <Input.Password
                            type="password"
                            placeholder="Please enter password"
                            autoComplete={"off"}
                        />
                    </FormItem>

                    <FormItem
                        layout="vertical"
                        triggerPropName="checked"
                        field="agreeToTerms"
                        rules={[{
                            required: true,
                            type: "boolean",
                            true: true,
                            message: "To continue, please agree to the Terms & Conditions."
                        }]}
                        style={{ marginBottom: 10, textAlign: "left" }}
                    >
                        <Checkbox>
                            I agree with
                            <Link
                                href={"https://we-rule.com/terms-conditions"}
                                target="_blank"
                            >
                                Terms & Conditions
                            </Link>
                        </Checkbox>
                    </FormItem>
                    <FormItem layout="vertical">
                        <Row style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 24,
                            marginTop: 10
                        }}>
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    onGoBack();
                                }}
                                size={"large"}
                                shape={"round"}
                            >
                                Go Back
                            </Button>
                            <Button
                                htmlType="submit"
                                style={{
                                    color: buttonTextColor,
                                    backgroundColor: buttonColor
                                }}
                                size={"large"}
                                shape={"round"}
                            >
                                Create Account
                            </Button>
                        </Row>
                    </FormItem>
                </Form>
            </div>
        </div>
    ) : (
        <div
            style={{
                justifyContent: "center",
                backgroundColor: boxBackgroundColor,
                maxWidth: 480,
                width: "calc(100% - 40px)",
                margin: "0 auto",
                padding: "20px",
                borderRadius: 8,
            }}
        >
            <Logo
                size={350}
                style={{
                    marginTop: 10,
                    maxWidth: "100%",
                }}
            />
            <div style={{ padding: 20, marginTop: 10 }}>
                <Typography.Text
                    style={{ fontSize: 20, color: colorAccent1, wordBreak: "break-word" }}
                    type="success"
                    className={"alice-regular"}
                >
                    To create an account, first tell us who you are.
                </Typography.Text>

                <Row justify="center" style={{ marginTop: 40, marginBottom: 20 }}>
                    <Button
                        style={{
                            color: buttonTextColor,
                            backgroundColor: buttonColor,
                            height: 40,
                            borderRadius: 20,
                        }}
                        onClick={onMentorPick}
                        shape={"round"}
                        size={"large"}
                    >
                        <b>Mentor</b>
                    </Button>
                    <Button
                        style={{
                            color: buttonTextColor,
                            backgroundColor: buttonColor,
                            marginLeft: 16,
                            height: 40,
                            borderRadius: 20,
                        }}
                        onClick={onMenteePick}
                        shape={"round"}
                        size={"large"}
                    >
                        <b>Mentee</b>
                    </Button>
                </Row>

                <div
                    style={{
                        marginTop: 40,
                        maxWidth: "380px",
                        width: "100%",
                        margin: "60px auto 0",
                    }}
                >
                    <Typography.Text
                        style={{
                            fontSize: 16,
                            color: "black",
                            fontStyle: "italic",
                            fontWeight: "bold",
                            wordBreak: "break-word",
                        }}
                    >
                        No one who achieved their dreams did it alone. Most greatness starts
                        with great mentorship.
                    </Typography.Text>
                </div>
            </div>

            <div style={{ textAlign: "center", marginTop: 60 }}>
                <Typography.Paragraph>
                    Already have an account?
                    <NavLink to={routes.LOGIN}>
                        <Button
                            style={{ padding: "0 0 0 4px" }}
                            type="text"
                            status="success"
                        >
                            <span style={{ color: colorAccent1 }}>Log in</span>
                        </Button>
                    </NavLink>
                </Typography.Paragraph>
            </div>
        </div>
    );
};
