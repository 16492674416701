import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Notification,
} from "@arco-design/web-react";

import { IconEdit } from "@arco-design/web-react/icon";
import { PostApi } from "../types/types";
import {
  MAX_POST_LENGTH,
  MAX_TITLE_LENGTH,
  SYMBOLS_COUNTER_NEEDED_LENGTH,
} from "../constants";

interface IUpdatePostModalProps {
  post: PostApi;
  onUpdatePost: (postId: number, updatedPost: PostApi) => void;
}

const UpdatePostModal = ({ post, onUpdatePost }: IUpdatePostModalProps) => {
  const [visible, setVisible] = useState(false);
  const initialValues = { heading: post.heading, content: post.content };
  const [updatingPost, setUpdatingPost] = useState(initialValues);

  const showModal = () => {
    setVisible(true);
  };

  const handleUpdate = () => {
    const { heading, content } = updatingPost;
    if (heading.trim() === "" || content.trim() === "") {
      Notification.error({
        content: "Please enter a title and content for your post",
      });
      return;
    }

    if (post.id) {
      const updatedPost: PostApi = {
        ...post,
        heading,
        content,
        likes: post.likes === "[]" ? JSON.parse(post.likes) : [],
        comments:
          typeof post.comments === "string" ? JSON.parse(post.comments) : [],
        updated_at: new Date().toISOString(),
      };
      onUpdatePost(post.id, updatedPost);
      setVisible(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setUpdatingPost(initialValues);
  };

  return (
    <div>
      <Button
        style={{
          padding: 0,
          backgroundColor: "transparent",
        }}
        onClick={showModal}
      >
        <IconEdit />
      </Button>

      <Modal
        title="Update Your Post"
        visible={visible}
        onOk={handleUpdate}
        onCancel={handleCancel}
        okText="Update"
        cancelText="Cancel"
        className="feed-modal"
      >
        <Form layout="vertical">
          <Form.Item label="Title">
            <Input
              value={updatingPost.heading}
              onChange={(value) =>
                setUpdatingPost({ ...updatingPost, heading: value })
              }
              placeholder="Post title"
              maxLength={MAX_TITLE_LENGTH}
            />
            {updatingPost.heading.length >
              SYMBOLS_COUNTER_NEEDED_LENGTH.TITLE && (
              <div style={{ textAlign: "right" }}>
                {updatingPost.heading.length} / {MAX_TITLE_LENGTH}
              </div>
            )}
          </Form.Item>
          <Form.Item label="Content">
            <Input.TextArea
              value={updatingPost.content}
              onChange={(value: string) =>
                setUpdatingPost({ ...updatingPost, content: value })
              }
              rows={5}
              maxLength={MAX_POST_LENGTH}
              placeholder="Write your post here..."
            />
            {updatingPost.content.length >
              SYMBOLS_COUNTER_NEEDED_LENGTH.POST && (
              <div style={{ textAlign: "right" }}>
                {updatingPost.content.length} / {MAX_POST_LENGTH}
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatePostModal;
