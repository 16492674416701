import { Button, Modal, Input, Select } from "@arco-design/web-react";
import { buttonColor, buttonTextColor } from "../../../../utils/cssVariables";
import './ConversationReportDialog.css';
import { useSendbirdStateContext } from "@sendbird/uikit-react";
import {
  ReportCategoryInfo
} from "@sendbird/chat/lib/__definition";
import { useEffect, useState } from "react";
import { SendbirdChatType } from "@sendbird/uikit-react/types/lib/types";

type Props = {
  visible: boolean;
  onReportSend: (message: string, category: ReportCategoryInfo) => void;
  onClose: () => void;
}

export function ConversationReportDialog({ visible, onReportSend, onClose }: Props) {
  const TextArea = Input.TextArea;
  const Option = Select.Option;

  const { stores: { sdkStore } } = useSendbirdStateContext();
  const sdk: SendbirdChatType = sdkStore.sdk;

  const [reportCategoryList, setReportCategoryList] = useState<ReportCategoryInfo[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      if (sdk) {
        const list: ReportCategoryInfo[] = await sdk.getReportCategoryInfoList();

        if (list && list.length) {
          setReportCategoryList(list);
        }
      }
    }

    if (visible) {
      fetchData().catch(console.error);
    }
  }, [visible]);

  const handleReportSend = () => {
    onReportSend(message, reportCategoryList.find(category => category.name === selectedCategory) as ReportCategoryInfo);
    onClose();
  }

  return (
    <Modal visible={visible} title="Report" footer={null} onCancel={onClose} className="conversation-report-dialog">
      <form>
        <div className="conversation-report-dialog__content">
          <Select placeholder="Select category" onChange={setSelectedCategory} value={selectedCategory}>
            {reportCategoryList.map((category) => (
              <Option key={category.name} value={category.name}>
                <span className="conversation-report-dialog__category-option">{category.name}</span>
              </Option>
            ))}
          </Select>
          <TextArea placeholder='Enter your report...' value={message} onChange={setMessage} rows={5} />
          <Button
            style={{
              color: buttonTextColor,
              backgroundColor: buttonColor,
            }}
            size={"default"}
            shape={"round"}
            onClick={handleReportSend}
          >
            Send
          </Button>
        </div>
      </form>
    </Modal>
  )
}