import { Modal, List, Input, Button } from "@arco-design/web-react";

import { useSendbirdStateContext } from "@sendbird/uikit-react";
import { useEffect, useState } from "react";
import { User } from "@sendbird/chat/lib/__definition";
import { buttonColor, buttonTextColor } from "../../../../utils/cssVariables";

import "./StartConversationDialog.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

type Props = {
  visible: boolean;
  onClose: () => void;
  onMessagingStart: (user: User) => void;
};

export function StartConversationDialog({
  visible,
  onClose,
  onMessagingStart,
}: Props) {
  const currentUserID = useSelector(
    (state: RootState) => state.user.user?.id
  );
  const [usersList, setUsersList] = useState<User[]>([]);
  const [blockedUserIds, setBlockedUserIds] = useState<string[]>([]);
  const [filter, setFilter] = useState<string>("");

  const {
    stores: {
      sdkStore: { sdk },
    },
  } = useSendbirdStateContext();
  const usersQuery = sdk.createApplicationUserListQuery?.({
    nicknameStartsWithFilter: filter,
    limit: 5,
  });
  const bUsersQuery = sdk.createBlockedUserListQuery?.();

  useEffect(() => {
    const fetchData = async () => {
      const usersData: User[] = await usersQuery?.next();

      if (usersData && usersData.length) {
        setUsersList(
          usersData.filter((user) => user.userId !== currentUserID?.toString())
        );
      }
    };

    if (visible && filter) {
      fetchData().catch(console.error);
    } else {
      setUsersList([]);
    }
  }, [filter, visible]);

  useEffect(() => {
    const fetchData = async () => {
      const blockedUsersData: User[] = await bUsersQuery?.next();

      if (blockedUsersData && blockedUsersData.length) {
        setBlockedUserIds(blockedUsersData.map((user) => user.userId));
      }
    };

    if (visible) {
      fetchData().catch(console.error);
    }
  }, [visible]);

  const handleMessagingStart = (user: User) => {
    onMessagingStart(user);
    onClose();
  };

  const handleUnblockAndMessagingStart = (user: User) => {
    sdk.unblockUserWithUserId(user.userId).then(() => {
      onMessagingStart(user);
      onClose();
    });
  };

  const renderUser = (item: User) => {
    const isBlocked = blockedUserIds.includes(item.userId);

    return (
      <List.Item
        key={item.userId}
        actions={[
          <Button
            style={{
              color: buttonTextColor,
              backgroundColor: buttonColor,
            }}
            size={"mini"}
            shape={"round"}
            onClick={() =>
              isBlocked
                ? handleUnblockAndMessagingStart(item)
                : handleMessagingStart(item)
            }
          >
            {isBlocked ? "Unblock and start messaging" : "Start messaging"}
          </Button>,
        ]}
      >
        <List.Item.Meta
          title={
            <div>
              {item.nickname}
              {isBlocked && (
                <span className="start-conversation-dialog__blocked-user">
                  Blocked
                </span>
              )}
            </div>
          }
        />
      </List.Item>
    );
  };

  return (
    <Modal
      title="Choose a user to send a message"
      visible={visible}
      onCancel={onClose}
      autoFocus
      focusLock={true}
      className={"start-conversation-dialog"}
      footer={null}
    >
      <div className="start-conversation-dialog__content">
        <Input
          allowClear
          value={filter}
          onChange={setFilter}
          placeholder="Enter name..."
        />
        <List
          className="list-demo-actions"
          size="small"
          dataSource={usersList}
          render={renderUser}
          noDataElement={
            <div className="start-conversation-dialog__no-users-message">
              Start typing to find some users
            </div>
          }
        />
      </div>
    </Modal>
  );
}
