import './AdminBanner.css';
import { useEffect, useState } from "react";
import classNames from "classnames";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { Button, Upload } from "@arco-design/web-react";
import { useBreakpoint } from "../../../../utils/hooks/useBreakpoint";
import axios, { AxiosResponse } from "axios";
import { endpoints } from "../../../../api/endpoints";
import { UploadItem } from "@arco-design/web-react/es/Upload";
import { Banner } from "../../../../models/Banners";
import { getAuth } from 'firebase/auth';
import { cachedToken } from '../../../../setupAxios';

export function AdminBanner() {
  const { isMobile } = useBreakpoint('mobile');

  const [publishedBanner, setPublishedBanner] = useState<Banner | null>(null);
  const [previewBanner, setPreviewBanner] = useState<Banner | null>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(true);
  const [fileList, setFileList] = useState<(UploadItem | Banner)[]>([]);

  const handleBannersLoaded = (banners?: Banner[]) => {
    if (banners && banners?.length > 0) {
      const preview = banners.find(banner => banner.status === 'preview')|| null;
      const published = banners.find(banner => banner.status === 'published')|| null;
      setPreviewBanner(preview);
      preview && setFileList([preview]);
      setPublishedBanner(published);
      setIsEditMode(!!preview || !published);
    } else {
      setPreviewBanner(null);
      setPublishedBanner(null);
      setIsEditMode(true);
    }
  }

  const getBanners = () => {
    axios
      .get(`${endpoints.getBanner}`)
      .then((response: AxiosResponse<Banner[]>) => {
        handleBannersLoaded(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getBanners();
  }, [])

  const handleSaveBanner = () => {
    axios
      .post(`${endpoints.admin.publishBanner}?id=${previewBanner?.id}`)
      .then((response: AxiosResponse<Banner[]>) => {
        handleBannersLoaded(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeletePreview = () => {
    axios
      .delete(endpoints.admin.deletePreviewBanner)
      .then((response: AxiosResponse<Banner[]>) => {
        handleBannersLoaded(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

    const [idToken, setIdToken] = useState<string | null>(null);
  
  
    useEffect(() => {
      const loadToken = async () => {
  
        let token = cachedToken;
  
        if (!token) {
          const user = getAuth().currentUser;
          if (user) {
            token = await user.getIdToken();
          } else {
          }
        }
        if (token) {
          setIdToken(token);
          console.log("Avatar upload token:", token);
        } else {
          console.warn("No token available for avatar upload");
        }
      };
  
      void loadToken();
    }, [cachedToken, getAuth().currentUser]);

  return (
    <div className="admin-banner">
      <div className="admin-banner__header">
        <Button
          className={classNames('admin-banner__icon-button', { 'admin-banner__icon-button--active': isEditMode })}
          shape={"round"}
          onClick={() => {
            setIsEditMode(true);
          }}
        >
          <EditIcon/>
        </Button>
      </div>
      {isEditMode ? (
        <div className="admin-banner__upload-wrapper">
          <Upload
            drag
            withCredentials={true}
            limit={1}
            accept="image/jpeg,image/png"
            showUploadList={{
              previewIcon: null,
            }}
            action={endpoints.admin.uploadBanner}
            headers={{ Authorization: `Bearer ${idToken}` }}
            fileList={
              previewBanner ? [
                {
                  uid: previewBanner.id.toString(),
                  url: previewBanner.url,
                }
              ] : undefined
            }
            listType="picture-card"
            onChange={(fileList, file) => {
              if (file.status === "done") {
                handleBannersLoaded(file.response as Banner[]);
                setFileList(fileList)
              }
            }}
          />
          <div className="admin-banner__buttons">
            <Button
              className="admin-banner__button admin-banner__button-secondary"
              shape={"round"}
              size={isMobile ? 'small' : 'large'}
              onClick={handleDeletePreview}
            >
              Cancel
            </Button>
            <Button
              className="admin-banner__button admin-banner__button-primary"
              shape={"round"}
              size={isMobile ? 'small' : 'large'}
              onClick={handleSaveBanner}
              disabled={!fileList.length}
            >
              Save
            </Button>
          </div>
        </div>
        ) : (
        <div className="admin-banner__image-wrapper">
          <img className="admin-banner__image" src={publishedBanner?.url} alt="banner"/>
        </div>
        ) }
    </div>
  );
}