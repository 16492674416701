import { useMediaQuery } from 'react-responsive';

const breakpoints = {
  mobile: '(max-width: 767.99px)',
  desktop: '(min-width: 768px)',
};

type BreakpointKey = keyof typeof breakpoints;

export function useBreakpoint<K extends BreakpointKey>(breakpointKey: K) {
  const bool = useMediaQuery({
    query: breakpoints[breakpointKey],
  });
  const capitalizedKey = breakpointKey[0].toUpperCase() + breakpointKey.substring(1);
  type Key = `is${Capitalize<K>}`;
  return {
    [`is${capitalizedKey}`]: bool,
  } as Record<Key, boolean>;
}
