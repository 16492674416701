import { User } from "../../models/Person";
import { Avatar, Button, Card, Tag } from "@arco-design/web-react";
import { ReactComponent as LocationIcon } from "../../assets/icons/location.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/linkedin.svg";
import { buttonColor, buttonTextColor } from "../../utils/cssVariables";

import './UserCard.css';
import { routes } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { IconLink } from "@arco-design/web-react/icon";
import { fixUrl } from "../../utils/fixUrl";

type Props = {
  user: User;
  hideActions?: boolean;
  withShadow?: boolean;
}

export function UserCard({ user, hideActions, withShadow }: Props) {
  const navigate = useNavigate();

  const handleLinkOpen = (e: Event, url?: string) => {
    e.preventDefault();

    if (url) {
      window.open(fixUrl(url), "_blank")
    }
  }

  return (
    <Card className={classNames('user-card', { ['user-card__with-shadow']: withShadow })} bordered={false} hoverable>
      <Avatar size={64} className="user-card__avatar">
        <img
          alt={user.name}
          src={user.avatar_url}
        />
      </Avatar>
      <div className="user-card__card-info">
        <div className="user-card__card-info__general-info">
          <div className="user-card__name">{user.name}</div>
          <div className="user-card__info-row">
            {user.shortDescription && <div>{user.shortDescription}</div>}
            {user.location && <div className="user-card__location"><LocationIcon/>{user.location}</div>}
          </div>

        </div>
        <div className="user-card_categories">
          {user.categories && user.categories?.map((category) => (
            <Tag className="user-card__category-tag" size="small" key={category}>{category}</Tag>
          ))}
        </div>
        {!hideActions && (
          <div className="user-card__action-buttons">
            {user.website && (
              <Button
              className="user-card__icon-button user-card__icon-button--primary"
              size="mini"
              shape={"round"}
              onClick={(e) => handleLinkOpen(e, user.website)}
            >
              <IconLink fontSize={18}/>
            </Button>
            )}
            {user.linkedIn && (
              <Button
                className="user-card__icon-button"
                size="mini"
                shape={"round"}
                onClick={(e) => handleLinkOpen(e, user.linkedIn)}
              >
                <LinkedinIcon/>
              </Button>
            )}
            <Button
              style={{
                color: buttonTextColor,
                backgroundColor: buttonColor,
              }}
              size="mini"
              shape={"round"}
              onClick={(e) => {
                e.preventDefault();
                navigate(`${routes.ADMIN_USER_BOOKING.replace(":id", user.id.toString())}`);
              }}
            >
              Book meeting
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
}