import { Card, Grid } from "@arco-design/web-react";

import "./NavigationCards.css";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes/routes";

const { GridItem } = Grid;

export function NavigationCards() {
  const navigate = useNavigate();

  return (
    <div>
      <Grid
        cols={{ xs: 2, sm: 2, md: 4 }}
        colGap={16}
        rowGap={16}
        className="admin-panel"
      >
        <GridItem>
          <Card
            hoverable
            bordered={false}
            className="admin-panel-card admin-panel-card__user-list"
            onClick={() => navigate(routes.ADMIN_USERS)}
          >
            User list
          </Card>
        </GridItem>
        <GridItem>
          <Card
            hoverable
            bordered={false}
            className="admin-panel-card admin-panel-card__create-user"
            onClick={() => navigate(routes.ADMIN_USER_CREATE)}
          >
            Create user
          </Card>
        </GridItem>
        <GridItem>
          <Card
            hoverable
            bordered={false}
            className="admin-panel-card admin-panel-card__meetings"
            onClick={() => navigate(routes.ADMIN_MEETINGS)}
          >
            Meetings
          </Card>
        </GridItem>
        <GridItem>
          <Card
            hoverable
            bordered={false}
            className="admin-panel-card admin-panel-card__statistics"
            onClick={() => navigate(routes.ADMIN_STATISTICS)}
          >
            Statistics
          </Card>
        </GridItem>
      </Grid>
    </div>
  );
}
