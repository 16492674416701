import { Button, Typography} from "@arco-design/web-react";
import {useLocation, useNavigate} from "react-router-dom";
import { routes } from "../routes/routes";
import { ReactNode, } from "react";

import { Logo } from "../components/Logo";
import { Grid } from "@arco-design/web-react";

import { boxBackgroundColor,  colorAccent1} from "../utils/cssVariables";
import { IconEmail } from "@arco-design/web-react/icon";
import Paragraph from "@arco-design/web-react/es/Typography/paragraph";
import axios from "axios";
import { endpoints } from "../api/endpoints";
import { getAuth } from "firebase/auth";

const Row = Grid.Row;

export const ConfirmEmail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    axios.post(
        endpoints.confirmedEmail,
        {
            email: email,
        }
    );
    const handleLoginRedirect = async () => {
        const user = getAuth();
        const idToken = await user.currentUser?.getIdToken();
        if(idToken) {
            await axios.get(endpoints.whoami, {
                headers: { Authorization: `Bearer ${idToken}` },
            });
            navigate(routes.profile);
        } else {
            navigate(routes.profile);
        }
    };

    const PageWrapper = ({children}: {children: ReactNode}) => (
        <div
            style={{
                justifyContent: "center",
                backgroundColor: boxBackgroundColor,
                maxWidth: 480,
                width: "calc(100% - 40px)",
                margin: "0 auto",
                padding: "20px",
                borderRadius: 8,
            }}
        >
            <Logo
                size={350}
                style={{
                    marginTop: 10,
                    width: "100%",
                }}
            />
            {children}
        </div>
    )

    return <PageWrapper>
        <Row justify="center" style={{alignItems: 'center', gap: 12, marginTop: 40, marginBottom: 24 }}>
            <IconEmail style={{ fontSize: 32, stroke: colorAccent1 }}/>
            <Typography.Text
                style={{ fontSize: 20, color: colorAccent1, wordBreak: "break-word" }}
                type="success"
                className={"alice-regular"}
            >Email Confirmation</Typography.Text>
        </Row>

        <Typography.Text style={{ wordBreak: "break-word" }}>
            {"Your email has been successfully verified! 🎉 "}
        </Typography.Text>
        <Paragraph style={{ wordBreak: "break-word" }}>{"You can now log in to your account. Please click the button below to go to the login page."}</Paragraph>
        <Row style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 24,
            marginTop: 40
        }}>
            <Button
                onClick={handleLoginRedirect}
                size={"large"}
                shape={"round"}
            >
                Go to the App
            </Button>
        </Row>
    </PageWrapper>
};