import { Capacitor } from "@capacitor/core";

export let HOST = "";
if (
  window.location.host.includes("localhost") ||
  window.location.host.includes("127.0.0.1")
) {
  HOST = "http://ec2-54-209-123-176.compute-1.amazonaws.com";
}
if (Capacitor.isNativePlatform()) {
  HOST = "http://ec2-54-209-123-176.compute-1.amazonaws.com";
}

export const endpoints = {
  whoami: `${HOST}/api/v1/whoami`,
  login: `${HOST}/unprotected/api/v1/login`,
  signup: `${HOST}/api/v1/user/signup`,
  recoverAccount: `${HOST}/unprotected/api/v1/user/recover-account`,
  resetPassword: `${HOST}/unprotected/api/v1/user/reset-password`,
  confirmEmail: `${HOST}/unprotected/api/v1/user/send-confirm-email`,
  confirmedEmail: `${HOST}/unprotected/api/v1/user/confirm-email`,
  meetings: `${HOST}/api/v1/meetings/list`,
  meetingsWithUser: `${HOST}/api/v1/meetings/list/`,
  updateProfile: `${HOST}/api/v1/profile/update`,
  currentProfile: `${HOST}/api/v1/profile/current`,
  getProfile: `${HOST}/api/v1/profile/public/`,
  addSlot: `${HOST}/api/v1/meetings/slot`,
  getSlots: `${HOST}/api/v1/meetings/slots`,
  deleteSlot: `${HOST}/api/v1/meetings/slot/`,
  getAvailableHours: `${HOST}/api/v1/meetings/slots/available/`,
  getPublicProfile: `${HOST}/api/v1/profile/public/`,
  getSearchMentors: `${HOST}/api/v1/search/mentors?limit=30`,
  getRandomMentors: `${HOST}/api/v1/search/random?limit=3`,
  getSearchFilters: `${HOST}/api/v1/search/filters`,
  bookMeeting: `${HOST}/api/v1/meetings/book`,
  cancelMeeting: `${HOST}/api/v1/meetings/cancel`,
  logout: `${HOST}/api/v1/logout`,
  getAvatar: `${HOST}/api/v1/user/avatar/`,
  addAvatar: `${HOST}/api/v1/user/upload-avatar`,
  getPost: (postId: number) => `${HOST}/api/v1/feed/${postId}`,
  getPosts: (limit = 10, offset = 0) =>
    `${HOST}/api/v1/feed?limit=${limit}&offset=${offset}`,
  getAllPosts: `${HOST}/api/v1/feed`,
  createPost: `${HOST}/api/v1/feed`,
  updatePost: (postId: number) => `${HOST}/api/v1/feed/${postId}`,
  deletePost: (postId: number) => `${HOST}/api/v1/feed/${postId}`,
  getBanner: `${HOST}/api/v1/banner`,
  admin: {
    getUsers: `${HOST}/api/v1/admin/users`,
    getUser: `${HOST}/api/v1/admin/users`,
    bookMeeting: `${HOST}/api/v1/admin/meetings`,
    updateMeeting: `${HOST}/api/v1/meetings`,
    deleteMeeting: `${HOST}/api/v1/admin/meetings`,
    createUser: `${HOST}/api/v1/admin/users`,
    deleteUser: `${HOST}/api/v1/admin/users`,
    resetPassword: `${HOST}/api/v1/admin/reset-password`,
    uploadBanner: `${HOST}/api/v1/admin/banner/upload`,
    publishBanner: `${HOST}/api/v1/admin/banner/publish`,
    deletePreviewBanner: `${HOST}/api/v1/admin/banner/cancel`,
    deleteBanner: `${HOST}/api/v1/admin/banner/delete`,
    getStatistics: `${HOST}/api/v1/admin/statistics`,
    getCSVReport: `${HOST}/api/v1/admin/csvreport`,
    getMeetings: `${HOST}/api/v1/meetings/`,
  },
};
