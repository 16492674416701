export const formatDate = (timestamp: string) => {
  const date = new Date(timestamp);
  const formattedTime = date.toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return formattedTime;
};
