import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  List,
  Comment,
  Button,
  Avatar,
  Notification,
} from "@arco-design/web-react";
import { IconHeart, IconMessage } from "@arco-design/web-react/icon";

import UpdatePostModal from "../UpdatePostModal";
import DeletePostModal from "../DeletePostModal";
import CommentComponent from "../Comment/Comment";
import CommentInput from "../CommentInput/CommentInput";
import ContentPreview from "./ContentPreview";

import { PostApi, CommentApi } from "../../types/types";

import { LoggedUser } from "../../../../../../models/Person";
import { updatePostRequest } from "../../../../../../api/feed";
import { getParsedArray } from "../../../../../../utils/getParsedArray";
import {endpoints, HOST} from "../../../../../../api/endpoints";
import { generateUniqueId } from "../../../../../../utils/useMockData";
import { formatDate } from "../../../../../../utils/formatDate";

interface IPostProps {
  post: PostApi;
  currentUser: LoggedUser;
  refetch: () => void;
  onUpdatePost: (postId: number, updatedPost: PostApi) => void;
  onDeletePost: (postId: number) => void;
  onLike: (post: PostApi, userID: number) => void;
}

const Post = ({
  post,
  currentUser,
  refetch,
  onUpdatePost,
  onDeletePost,
  onLike,
}: IPostProps) => {
  const {
    id: post_id,
    user_id,
    likes,
    comments,
    content,
    heading,
    created_at,
    updated_at,
  } = post;

  const [isMutating, setIsMutating] = useState<boolean>(false);

  const [newComment, setNewComment] = useState<string>("");
  const [expandedPost, setExpandedPost] = useState<number | null>(null);

  const parsedLikes = getParsedArray(likes);
  const parsedComments = getParsedArray(comments);

  const handleExpandComments = (postId: number) => {
    setExpandedPost(expandedPost === postId ? null : postId);
  };

  const { mutateAsync: addComment } = useMutation({
    mutationFn: updatePostRequest,
    onSuccess: () => {
      Notification.success({
        content: "Comment added successfully!",
      });
      refetch();
      setIsMutating(false);
    },
    onError: () => {
      Notification.error({
        content: "Error commenting post!",
      });
      setIsMutating(false);
    },
  });

  const handleAddComment = (post: PostApi) => {
    if (isMutating || newComment.trim() === "") return;

    setIsMutating(true);

    const addingComment = {
      content: newComment,
      user_id: currentUser.id,
      user_name: currentUser.name,
      comment_id: generateUniqueId(),
      avatar_url: currentUser.avatar_url,
      created_at: new Date().toISOString(),
      edited_at: new Date().toISOString(),
    };

    const likes = getParsedArray(post.likes);
    const comments = getParsedArray(post.comments);

    const updatedPost = {
      ...post,
      comments: [...comments, addingComment],
      likes: likes,
    };

    addComment([post.id, updatedPost])
      .then(() => {
        setNewComment("");
      })
      .finally(() => {
        setIsMutating(false);
      });
  };

  return (
    <List.Item
      style={{
        display: "flex",
        alignContent: "flex-start",
        alignItems: "center",
        paddingRight: currentUser.id !== post.user_id ? 66 : 20,
      }}
      actions={
        user_id === currentUser?.id || currentUser.isAdmin
          ? [
              <UpdatePostModal onUpdatePost={onUpdatePost} post={post} />,
              <DeletePostModal postId={post_id} onDeletePost={onDeletePost} />,
            ]
          : undefined
      }
      className="posts-actions-list"
    >
      <Comment
        className="post-actions"
        actions={[
          <div
            key={post_id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: 180,
              gap: 8,
            }}
          >
            <Button
              style={{
                padding: 0,
                backgroundColor: "transparent",
              }}
              onClick={() => onLike(post, currentUser.id)}
            >
              <span>
                <IconHeart
                  style={{
                    fill: parsedLikes?.includes(currentUser.id)
                      ? "red"
                      : "transparent",
                  }}
                />
                {parsedLikes.length || ""}
              </span>
            </Button>
            <Button
              onClick={() => post_id && handleExpandComments(post_id)}
              size="small"
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                padding: 0,
                gap: 4,
                minWidth: 140,
                backgroundColor: "transparent",
              }}
            >
              <IconMessage />
              <span
                style={{ display: "flex", justifyContent: "left", margin: 0 }}
              >
                {!parsedComments.length && "Add your comment"}
                {!!parsedComments.length &&
                  `${
                    expandedPost === post_id
                      ? "Hide comments"
                      : `Show comments - ${parsedComments.length}`
                  }`}
              </span>
            </Button>
          </div>,
        ]}
        content={
          <div
            style={{
              display: "flex",
              gap: 8,
              marginTop: 8,
              marginRight: currentUser.id !== post.user_id ? 26 : 8,
              textAlign: "left",
              wordBreak: "break-word",
            }}
          >
            <ContentPreview content={content} />
          </div>
        }
      />
      <List.Item.Meta
        avatar={
          post.avatar_url ? (
            <Avatar shape="circle" size={36} style={{ marginRight: 10 }}>
              <img src={post.avatar_url} alt={post.user_name} />
            </Avatar>
          ) : (
            <Avatar shape="circle" size={36} style={{ marginRight: 10 }} />
          )
        } 
        style={{
          display: "flex",
          alignItems: "flex-start",
          padding: 0,
          marginRight: currentUser.id !== post.user_id ? 26 : 8,
        }}
        title={
          <div style={{ display: "grid", justifyItems: "left" }}>
            <b
              className="post-title"
              style={{
                textAlign: "left",
                wordBreak: "break-word",
              }}
            >
              {heading}
            </b>
            <span
              className="post-author-name"
              style={{ fontSize: 12, fontWeight: 400 }}
            >
              {post.user_name}
            </span>
            <span
              style={{
                display: "flex",
                alignSelf: "center",
                fontSize: 10,
                fontWeight: 300,
              }}
            >
              {formatDate(created_at)}{" "}
              {created_at !== updated_at &&
                ` | Edited - ${formatDate(updated_at)}`}
            </span>
          </div>
        }
      />
      {expandedPost === post_id && (
        <div
          style={{
            display: "grid",
            gap: 8,
            marginTop: 12,
            paddingTop: 12,
            borderTop: "1px solid #e0e0e0",
            paddingRight: currentUser.id !== post.user_id ? 36 : 24,
          }}
        >
          <ul className="comments-list">
            {parsedComments &&
              parsedComments.map((comment: CommentApi) => (
                <CommentComponent
                  key={comment.comment_id}
                  comment={comment}
                  post={post}
                  currentUserId={currentUser.id}
                  isCurrentUserAdmin={currentUser.isAdmin}

                  isMutating={isMutating}
                  setIsMutating={setIsMutating}
                  refetch={refetch}
                  onUpdatePost={onUpdatePost}
                />
              ))}
          </ul>
          <CommentInput
            comment={newComment}
            post={post}
            isMutating={isMutating}
            onSetNewComment={setNewComment}
            onAddComment={handleAddComment}
          />
        </div>
      )}
    </List.Item>
  );
};

export default Post;
