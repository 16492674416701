import './MeetingCard.css';
import { Avatar, Button, Card, Grid } from "@arco-design/web-react";
import { AdminMeeting } from "../../models/Meeting";
import { useEffect, useState } from "react";
import { BookingForm } from "../BookingForm/BookingForm";
import { useBreakpoint } from "../../utils/hooks/useBreakpoint";
import DeleteMeetingModal from './DeleteMeetingModal';

type Props = {
  meeting: AdminMeeting;
  onUpdateMeeting: (date: string, time: number) => void;
  onDeleteMeeting: () => void;
}

const Row = Grid.Row;
const Col = Grid.Col;

const DEFAULT_AVATAR_URL = 'https://werule-s3-bucket.s3.eu-north-1.amazonaws.com/default.png';

export function MeetingCard({ meeting, onUpdateMeeting, onDeleteMeeting }: Props) {
  const weekDay = new Date(meeting.start_time).toLocaleDateString('en-US', { weekday: 'long' });
  const date = new Date(meeting.start_time).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric'
  });
  const time = new Date(meeting.start_time).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });

  const { isMobile } = useBreakpoint('mobile');

  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedDay, setUpdatedDay] = useState(meeting.date);
  const [updatedTime, setUpdatedTime] = useState(meeting.time);

  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const handleButtonDisabled = (value: boolean) => {
    setSaveButtonDisabled(value);
  };

  useEffect(() => {
    if(updatedDay === meeting.date || updatedTime === meeting.time) {
      handleButtonDisabled(true);
    }

    if (updatedDay !== meeting.date || updatedTime !== meeting.time) {
      handleButtonDisabled(false);
    }
  }, [meeting.date, meeting.time, updatedDay, updatedTime]);

  return (
    <Card bordered={false} className="meeting-card">
      <Row align="center">
        <Col span={8}>
          <div className="meeting-card__name">{meeting.mentor_name}</div>
          <Avatar size={64} className="user-card__avatar">
            <img
              alt={meeting.mentor_name}
              src={meeting.mentor_avatar_url || DEFAULT_AVATAR_URL}
            />
          </Avatar>
        </Col>
        <Col span={8}>
          {!isEditMode && (
            <div className="meeting-card__date-wrapper">
              <div className="meeting-card__week-day">{weekDay}</div>
              <div className="meeting-card__date">{date}</div>
              <div className="meeting-card__time">{time}</div>
            </div>
          )}
        </Col>
        <Col span={8}>
          <div className="meeting-card__name">{meeting.mentee_name}</div>
          <Avatar size={64} className="user-card__avatar">
            <img
              alt={meeting.mentee_name}
              src={meeting.mentee_avatar_url || DEFAULT_AVATAR_URL}
            />
          </Avatar>
        </Col>
      </Row>
      {isEditMode && (
        <BookingForm
          day={updatedDay}
          time={updatedTime}
          onDayChange={setUpdatedDay}
          onTimeChange={setUpdatedTime}
          userId={meeting.mentor_user_id.toString()}
          includeSelectedSlotInList
        />
      )}
      {isEditMode ? (
        <div className="meeting-card__buttons">
          <Button
            className="meeting-card__button meeting-card__button-primary"
            shape={"round"}
            size={isMobile ? 'small' : 'large'}
            onClick={() => {
              onUpdateMeeting(updatedDay, updatedTime);
              setIsEditMode(false);
            }}
            disabled={isSaveButtonDisabled}
          >
            Save
          </Button>
          <Button
            className="meeting-card__button meeting-card__button-secondary"
            shape={"round"}
            size={isMobile ? 'small' : 'large'}
            onClick={() => {
              setIsEditMode(false);
              setUpdatedDay(meeting.date);
              setUpdatedTime(meeting.time);
            }}
          >
            Cancel
          </Button>
        </div>
        ) : (
        <div className="meeting-card__buttons">
          <Button
            className="meeting-card__button meeting-card__button-primary"
            shape={"round"}
            size={isMobile ? 'small' : 'large'}
            onClick={() => setIsEditMode(true)}
          >
            Edit
          </Button>
          <DeleteMeetingModal onDeleteMeeting={onDeleteMeeting}/>
        </div>
      )}
    </Card>
  )
}