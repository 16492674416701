import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuProps,
  Grid,
  Avatar,
  Message,
} from "@arco-design/web-react";
import {
  IconHome,
  IconCalendar,
  IconSettings,
  IconUser,
  IconPoweroff,
  IconMenu,
  IconSearch,
  IconMessage,
} from "@arco-design/web-react/icon";
import { JSX } from "react/jsx-runtime";
import { Logo } from "../components/Logo";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { headerColor, headerTextColor } from "../utils/cssVariables";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../store/store";
import { useSendbirdStateContext } from "@sendbird/uikit-react";
import { UserEventHandler } from "@sendbird/chat";
import {getAuth, signOut} from "firebase/auth";
import {clearUser} from "../store/userSlice";

const MenuItem = Menu.Item;
const Row = Grid.Row;
const Col = Grid.Col;


export const BaseMenu = (
  props: JSX.IntrinsicAttributes & MenuProps & React.RefAttributes<unknown>
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [collapse, setCollapse] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const user = useSelector((state: RootState) => state.user.user);
  const defaultPage = user?.isAdmin ? routes.ADMIN_DASHBOARD : routes.DASHBOARD;

  const { stores: { sdkStore: { sdk } } } = useSendbirdStateContext();

  useEffect(() => {
    if (sdk) {
      sdk.addUserEventHandler?.('messagesCount', new UserEventHandler({
        onTotalUnreadMessageCountUpdated: (totalCount) => {
          setUnreadMessagesCount(totalCount);
        },
      }));
    }
  }, [sdk])
  const dispatch = useDispatch();
  const logOut = () => {
    const auth = getAuth();

    signOut(auth)
      .then(() => {
        dispatch(clearUser());
        Message.success({content: "Logged out successfully", closable: true, duration: 3000});

        setTimeout(() => {
          navigate(routes.LOGIN);
        }, 10);
      })
      .catch((error) => {
        console.error("Logout failed:", error);
        Message.error("Could not log out");
      });
  };

  const menuItems = (
    <>
      {!user?.isAdmin && (
        <NavLink to={routes.DASHBOARD}>
          <MenuItem
            key={routes.DASHBOARD}
            style={{ backgroundColor: headerColor, color: headerTextColor }}
          >
            <IconHome style={{ color: headerTextColor }} />
            Upcoming Meetings
          </MenuItem>
        </NavLink>
      )}
      {!user?.isAdmin && (
        <NavLink to={routes.SEARCH}>
          <MenuItem
            key={routes.SEARCH}
            style={{ backgroundColor: headerColor, color: headerTextColor }}
          >
            <IconSearch style={{ color: headerTextColor }} />
            Find Mentors
          </MenuItem>
        </NavLink>
      )}

      {user?.isMentor && !user?.isAdmin && (
        <NavLink to={routes.MENTORSETTINGS}>
          <MenuItem
            key={routes.MENTORSETTINGS}
            style={{ backgroundColor: headerColor, color: headerTextColor }}
          >
            <IconCalendar style={{ color: headerTextColor }} />
            Set your availability
          </MenuItem>
        </NavLink>
      )}

      {user?.isMentor && !user?.isAdmin && (
        <NavLink to={routes.MENTOR.replace(":id", user?.id.toString())}>
          <MenuItem
            key={routes.MENTOR.replace(":id", user?.id.toString())}
            style={{ backgroundColor: headerColor, color: headerTextColor }}
          >
            <IconUser style={{ color: headerTextColor }} />
            Your Profile
          </MenuItem>
        </NavLink>
      )}

      {!user?.isAdmin && (
        <NavLink to={routes.profile}>
          <MenuItem
            key={routes.profile}
            style={{ backgroundColor: headerColor, color: headerTextColor }}
          >
            <IconSettings style={{ color: headerTextColor }} />
            Edit your Profile
          </MenuItem>
        </NavLink>
      )}

      {user?.isAdmin && (
        <NavLink to={routes.DASHBOARD}>
          <MenuItem
            key={routes.DASHBOARD}
            style={{ backgroundColor: headerColor, color: headerTextColor }}
          >
            <IconHome style={{ color: headerTextColor }} />
            Home
          </MenuItem>
        </NavLink>
      )}

      {user?.isAdmin && (
        <NavLink to={routes.ADMIN_DASHBOARD}>
          <MenuItem
            key={routes.ADMIN_DASHBOARD}
            style={{ backgroundColor: headerColor, color: headerTextColor }}
          >
            Admin Dashboard
          </MenuItem>
        </NavLink>
      )}

      {user?.isAdmin && (
        <NavLink to={routes.ADMIN_USERS}>
          <MenuItem
            key={routes.ADMIN_USERS}
            style={{ backgroundColor: headerColor, color: headerTextColor }}
          >
            Users list
          </MenuItem>
        </NavLink>
      )}

      {user?.isAdmin && (
        <NavLink to={routes.ADMIN_USER_CREATE}>
          <MenuItem
            key={routes.ADMIN_USER_CREATE}
            style={{ backgroundColor: headerColor, color: headerTextColor }}
          >
            Create User
          </MenuItem>
        </NavLink>
      )}

      {user?.isAdmin && (
        <NavLink to={routes.ADMIN_MEETINGS}>
          <MenuItem
            key={routes.ADMIN_MEETINGS}
            style={{ backgroundColor: headerColor, color: headerTextColor }}
          >
            Meetings
          </MenuItem>
        </NavLink>
      )}

      {user?.isAdmin && (
        <NavLink to={routes.ADMIN_STATISTICS}>
          <MenuItem
            key={routes.ADMIN_STATISTICS}
            style={{ backgroundColor: headerColor, color: headerTextColor }}
          >
            Statistics
          </MenuItem>
        </NavLink>
      )}

      <NavLink to={routes.MESSAGES}>
        <MenuItem
          key={routes.MESSAGES}
          style={{ backgroundColor: headerColor, color: headerTextColor }}
        >
          <IconMessage style={{ color: headerTextColor }} />
          <div style={{
            position: "relative",
            display: "inline-block",
          }}>
            Messages
            {unreadMessagesCount > 0 && (
              <div style={{
                position: "absolute",
                top: '5px',
                right: '-10px',
                backgroundColor: '#d24e4e',
                width: 8,
                height: 8,
                borderRadius: 10,
              }}/>
            )}
          </div>
        </MenuItem>
      </NavLink>

      <NavLink to="#" onClick={logOut}>
        <MenuItem
          key={routes.LOGIN}
          style={{ backgroundColor: headerColor, color: headerTextColor }}
        >
          <IconPoweroff style={{ color: headerTextColor }} />
          Log out
        </MenuItem>
      </NavLink>
    </>
  );

  return (
    <div style={{ width: "100%" }} className={"own-header"}>
      <Row style={{ paddingTop: 5, paddingBottom: 5 }} justify="end">
        <Col style={{ paddingTop: 2 }} flex="auto">
          <NavLink to={defaultPage} className={"own-header-logo"}>
            <Logo size={133} style={{ marginLeft: 10 }} />
          </NavLink>
        </Col>
        <Col
          flex="none"
          className={"display-only-on-desktop"}
          style={{ maxWidth: "100%" }}
        >
          <Menu
            style={{
              backgroundColor: headerColor,
              marginRight: 25,
            }}
            collapse={false}
            mode="horizontal"
            selectedKeys={[location.pathname]}
          >
            {menuItems}
          </Menu>
        </Col>
        <Col
          flex="auto"
          style={{ textAlign: "right" }}
          className={"display-only-on-mobile"}
        >
          {/*{user?.userID && (*/}
          {/*  <Avatar size={40} onClick={() => setCollapse(!collapse)}>*/}
          {/*    <img src={user?.avatarUrl} alt="avatar" style={{width: "100%"}}/>*/}
          {/*  </Avatar>*/}
          {/*)}*/}
          <div style={{
            position: "relative",
            display: "inline-block",
          }}>
            <IconMenu
              onClick={() => setCollapse(!collapse)}
              style={{
                cursor: "pointer",
                float: "right",
                fontSize: 35,
                marginLeft: 10,
                marginRight: 10,
                marginTop: 3,
              }}
            />
            {unreadMessagesCount > 0 && (
              <div style={{
                position: "absolute",
                top: '3px',
                right: '5px',
                backgroundColor: '#d24e4e',
                width: 10,
                height: 10,
                borderRadius: 10,
              }}/>
            )}
          </div>
          {collapse && (
            <Menu
              style={{
                position: "absolute",
                zIndex: 999,
                backgroundColor: headerColor,
                top: "43px",
                right: "5px",
                textAlign: "left",
                width: "100vw",
              }}
              collapse={false}
              mode="vertical"
              onClick={() => {
                setTimeout(() => {
                  setCollapse(false);
                }, 80);
              }}
            >
              {menuItems}
            </Menu>
          )}
        </Col>
      </Row>
    </div>
  );
};
