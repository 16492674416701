import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "../routes/routes";
import { Suspense } from "react";
import { Login } from "./Login";
import { Register } from "./Register";
import { ResetPassword } from "./ResetPassword";
import { Search } from "./Search";
import { Dashboard } from "./DashBoard/DashBoard";
import { MentorProfile } from "./MentorProfile";
import { ProfileSettings } from "./Profile";
import { MentorSettings } from "./MentorSettings";
import { Messages } from "./Messages/Messages";
import { UsersList } from "./Admin/UsersList/UsersList";
import { UserProfile } from "./Admin/UserProfile/UserProfile";
import { UserBooking } from "./Admin/UserBooking/UserBooking";
import { CreateUser } from "./Admin/CreateUser/CreateUser";
import { Meetings } from "./Admin/Meetings/Meetings";
import { AdminDashboard } from "./Admin/AdminDashboard/AdminDashboard";
import { Statistics } from "./Admin/Statistics/Statistics";
import { ConfirmResetPassword } from "./ConfirmResetPassword";
import { ConfirmEmail } from "./ConfirmEmail";

export const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={routes.LOGIN} />} />
      <Route
        path={routes.LOGIN}
        element={
          <Suspense fallback="Login">
            <Login />
          </Suspense>
        }
      />

      <Route
        path={routes.REGISTER}
        element={
          <Suspense fallback="Register">
            <Register />
          </Suspense>
        }
      />

      <Route
        path={routes.RESETPASSWORD}
        element={
          <Suspense fallback="ResetPassword">
            <ResetPassword />
          </Suspense>
        }
      />

      <Route
        path={routes.CONFIRM_RESET_PASSWORD}
        element={
          <Suspense fallback="ConfirmResetPassword">
            <ConfirmResetPassword />
          </Suspense>
        }
      />

      <Route
        path={routes.CONFIRM_EMAIL}
        element={
            <Suspense fallback="ConfirmEmail">
                <ConfirmEmail />
            </Suspense>
        }
      />

      <Route
        path={routes.SEARCH}
        element={
          <Suspense fallback="Search">
            <Search />
          </Suspense>
        }
      />

      <Route
        path={routes.DASHBOARD}
        element={
          <Suspense fallback="Dashboard">
            <Dashboard />
          </Suspense>
        }
      />

      <Route
        path={routes.MENTOR}
        element={
          <Suspense fallback="Mentor">
            <MentorProfile />
          </Suspense>
        }
      />

      <Route
        path={routes.profile}
        element={
          <Suspense fallback="Profile">
            <ProfileSettings />
          </Suspense>
        }
      />

      <Route
        path={routes.MENTORSETTINGS}
        element={
          <Suspense fallback="Profile">
            <MentorSettings />
          </Suspense>
        }
      />
      <Route
        path={routes.MESSAGES}
        element={
          <Suspense fallback="Messages">
            <Messages />
          </Suspense>
        }
      />
      <Route
        path={routes.ADMIN_STATISTICS}
        element={
          <Suspense fallback="Statistics">
            <Statistics />
          </Suspense>
        }
      />
      <Route
        path={routes.ADMIN_USERS}
        element={
          <Suspense fallback="Users list">
            <UsersList />
          </Suspense>
        }
      />
      <Route
        path={routes.ADMIN_USER}
        element={
          <Suspense fallback="User profile">
            <UserProfile />
          </Suspense>
        }
      />
      <Route
        path={routes.ADMIN_USER_BOOKING}
        element={
          <Suspense fallback="User booking meeting">
            <UserBooking />
          </Suspense>
        }
      />
      <Route
        path={routes.ADMIN_USER_CREATE}
        element={
          <Suspense fallback="User booking meeting">
            <CreateUser />
          </Suspense>
        }
      />
      <Route
        path={routes.ADMIN_MEETINGS}
        element={
          <Suspense fallback="Meeting">
            <Meetings />
          </Suspense>
        }
      />
      <Route
        path={routes.ADMIN_DASHBOARD}
        element={
          <Suspense fallback="Admin dashboard">
            <AdminDashboard />
          </Suspense>
        }
      />
    </Routes>
  );
};
