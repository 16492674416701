import axios from "axios";

import { endpoints } from "./endpoints";
import { Post, PostApi } from "../pages/DashBoard/partials/Feed/types/types";
import { PostsResponse } from "../pages/DashBoard/partials/Feed/Feed";

export const fetchPost = async (post_id: number) => {
  const { data } = await axios.get(endpoints.getPost(post_id));
  return data;
};

export const fetchPosts = async (pageParam = 0): Promise<PostsResponse> => {
  const limit = 10;
  const { data } = await axios.get(endpoints.getPosts(limit, pageParam));

  return data;
};

export const createPostRequest = async (newPost: Post) => {
  const { data } = await axios.post(endpoints.createPost, newPost);
  return data;
};

export const updatePostRequest = async ([postId, updatedPost]: [
  number,
  PostApi
]): Promise<PostApi> => {
  const { data } = await axios.post(endpoints.updatePost(postId), updatedPost);
  return data;
};

export const deletePostRequest = async (postId: number): Promise<number> => {
  const response = await axios.delete(endpoints.deletePost(postId));
  if (response.status !== 200) {
    throw new Error("Failed to delete post");
  }
  return postId;
};
