import {Button, Message} from "@arco-design/web-react";
import { GoogleOutlined } from "@ant-design/icons";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { endpoints } from "../api/endpoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { clearUser, setUser } from "../store/userSlice";
import { useDispatch } from "react-redux";

export const GoogleAuthButton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleGoogleLogin = async () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();

        try {
            dispatch(clearUser());

            const result = await signInWithPopup(auth, provider);
            const firebaseUser = result.user;
            const idToken = await firebaseUser.getIdToken();
            const response = await axios.get(endpoints.whoami, {
                headers: { Authorization: `Bearer ${idToken}` },
            });

            dispatch(setUser({ ...response.data }));
            navigate(routes.DASHBOARD);
            window.location.reload();
        } catch (error) {
            Message.error("This account doesn't exist. Please use a different email or create new account.");
            console.error("Google login failed:", error);
        }
    };

    return (
        <Button
            onClick={handleGoogleLogin}
            type="primary"
            shape="round"
            icon={<GoogleOutlined />}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 8, width: "100%", marginTop: 10 }}
        >
            Log in with Google
        </Button>
    );
};
