import { Button, Input } from "@arco-design/web-react/";
import axios from "axios";
import { endpoints } from "../../../api/endpoints";
import { useEffect, useState } from "react";
import { User } from "../../../models/Person";
import { Checkbox, List } from "@arco-design/web-react";
import { routes } from "../../../routes/routes";
import { NavLink } from "react-router-dom";
import { UserCard } from "../../../components/UserCard/UserCard";
import './UsersList.css';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import { setUsersList, setSelectedUser } from "../../../store/adminSlice";

const InputSearch = Input.Search;

const FILTER_OPTIONS = ['mentor', 'mentee'];

const getFilteredProfiles = (list: User[], searchQuery: string, filter: string[]) => {
  return list.filter((profile) => {
    const isMatchSearchString = searchQuery ? profile.name.toLowerCase().includes(searchQuery.toLowerCase()) : true;
    const userRole = profile.isMentor ? 'mentor' : 'mentee';
    const isMatchFilter = filter.includes(userRole);
    return isMatchFilter && isMatchSearchString;
  });
}

export function UsersList() {
  const dispatch = useDispatch();

  const usersList = useSelector((state: RootState) => state.admin.usersList);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filter, setFilter] = useState<string[]>(FILTER_OPTIONS);
  const [profiles, setProfiles] = useState<User[]>(usersList);
  const [filteredProfiles, setFilteredProfiles] = useState<User[]>(getFilteredProfiles(usersList, searchQuery, filter));
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getProfiles = async () => {
    axios
      .get(`${endpoints.admin.getUsers}`)
      .then((response) => {
        setProfiles(response.data);
        setFilteredProfiles(getFilteredProfiles(response.data, searchQuery, filter));
        dispatch(
          setUsersList(response.data)
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }


  useEffect(() => {
    getProfiles().then();
  }, []);

  useEffect(() => {
    setFilteredProfiles(getFilteredProfiles(profiles, searchQuery, filter));
  }, [searchQuery, filter]);

  return (
    <div className="users-list">
      <h1 className="users-list__header">Users List</h1>
      <div className="users-list__filter-wrapper">
        <Checkbox.Group className="users-list__checkboxes" defaultValue={filter} onChange={setFilter}>
          {FILTER_OPTIONS.map((item) => {
            return (
              <Checkbox key={item} value={item}>
                {({ checked }) => {
                  return (
                    <Button
                      className="users-list__filter-button"
                      size={"small"}
                      shape={"round"}
                      tabIndex={-1} key={item}
                      type={checked ? 'primary' : 'default'}
                    >
                      {item}
                    </Button>
                  );
                }}
              </Checkbox>
            );
          })}
        </Checkbox.Group>
      </div>
      <InputSearch
        onChange={(e) => setSearchQuery(e)}
        placeholder="Search"
        onPressEnter={() => getProfiles()}
        className="users-list__search"
      />
      <List
        className="users-list__list"
        dataSource={filteredProfiles}
        bordered={false}
        loading={!filteredProfiles.length && isLoading}
        render={(user: User) => (
          <List.Item
            key={user.id}
          >
            <NavLink
              to={`${routes.ADMIN_USER.replace(":id", user.id.toString())}`}
              onClick={() => {
                dispatch(setSelectedUser(user))
              }}
            >
              <UserCard user={user}/>
            </NavLink>
          </List.Item>
        )}
      />
    </div>
  );
}