import './UserDetailedInfo.css';
import { User } from "../../models/Person";
import { Grid, List } from "@arco-design/web-react";

type Props = {
  user: User;
}

const Row = Grid.Row;
const Col = Grid.Col;

export function UserDetailedInfo({ user }: Props) {
  return (
    <List className="user-detailed-info">
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>Name</Col>
          <Col span={14}>{user.name}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>Email</Col>
          <Col span={14}>{user.email}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>LinkedIn</Col>
          <Col span={14}>{user.linkedIn || '-'}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>Location</Col>
          <Col span={14}>{user.location || '-'}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>Borough</Col>
          <Col span={14}>{user.borough || '-'}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>Gender</Col>
          <Col span={14}>{user.gender || '-'}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>Age</Col>
          <Col span={14}>{user.age || '-'}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>Race</Col>
          <Col span={14}>{user.race || '-'}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>Ethnicity</Col>
          <Col span={14}>{user.ethnicity || '-'}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>Education</Col>
          <Col span={14}>{user.education || '-'}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>Income Range</Col>
          <Col span={14}>{user.incomeRange || '-'}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>Occupation Status</Col>
          <Col span={14}>{user.occupationStatus || '-'}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>What is your professional experience level?</Col>
          <Col span={14}>{user.professionalExperience || '-'}</Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={8}>
          <Col span={10}>Current Industry</Col>
          <Col span={14}>{user.industries?.join(', ') || '-'}</Col>
        </Row>
      </List.Item>
      {!user.isMentor && (
        <List.Item>
          <Row gutter={8}>
            <Col span={10}>Which high growth industry interests you the most?</Col>
            <Col span={14}>{user.highGrowthIndustries?.join(', ') || '-'}</Col>
          </Row>
        </List.Item>
      )}
    </List>
  )
}