import {Typography} from "@arco-design/web-react";
import {headerColor, headerTextColor} from "../utils/cssVariables";
import {NavLink} from "react-router-dom";
import {Logo} from "../components/Logo";
import React from "react";

export const FooterContent = () => {
  return (
    <div
      style={{
        textAlign: "center",
        backgroundColor: headerColor,
        padding: "40px 5px",
      }}
    >
      <Typography.Title heading={3}>
        <Logo size={133} style={{marginLeft: 10}}/>
      </Typography.Title>
      <Typography.Paragraph>524 Broadway, New York, NY</Typography.Paragraph>
      <div>
        <NavLink
          to={"https://we-rule.com/who-we-are"}
          target="_blank"
          style={{
            marginLeft: 10,
            textTransform: "uppercase",
            textDecoration: "none",
            color: headerTextColor,
          }}
        >
          About
        </NavLink>
        <NavLink
          to={"https://we-rule.com/blog"}
          target="_blank"
          style={{
            marginLeft: 10,
            textTransform: "uppercase",
            textDecoration: "none",
            color: headerTextColor,
          }}
        >
          Blog
        </NavLink>
        <NavLink
          to={"https://we-rule.com/privacy-policy"}
          target="_blank"
          style={{
            marginLeft: 10,
            textTransform: "uppercase",
            textDecoration: "none",
            color: headerTextColor,
          }}
        >
          Privacy Policy
        </NavLink>
        <NavLink
          to={"https://we-rule.com/contact"}
          target="_blank"
          style={{
            marginLeft: 10,
            textTransform: "uppercase",
            textDecoration: "none",
            color: headerTextColor,
          }}
        >
          Contact
        </NavLink>
      </div>
    </div>
  );
};
