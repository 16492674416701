import { initializeApp } from "firebase/app";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBqoefaOOwZaJTz7SnAtzfiRIoEDc1ujXI",
    authDomain: "we-rule-71c1d.firebaseapp.com",
    projectId: "we-rule-71c1d",
    storageBucket: "we-rule-71c1d.firebasestorage.app",
    messagingSenderId: "832824611421",
    appId: "1:832824611421:web:ae5d6142d199149cd8f3b4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword };

export const registerUser = async (email: string, password: string) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
};

export const loginUser = async (email: string, password: string) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
};

export const logoutUser = async () => {
    await signOut(auth);
};