import React, { useState } from 'react';

import "@sendbird/uikit-react/dist/index.css";
import { GroupChannel as GroupChannelType } from "@sendbird/chat/lib/__definition";

import { useSelector } from "react-redux";
import { ConversationsList } from "./components/ConversationsList/ConversationsList";
import { Conversation } from "./components/Conversation/Conversation";
import { useBreakpoint } from "../../utils/hooks/useBreakpoint";

import { RootState } from "../../store/store";
import { Typography } from '@arco-design/web-react';

export const Messages = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const [selectedChannel, setSelectedChannel] = useState<GroupChannelType | null>(null);
  const { isMobile } = useBreakpoint('mobile');

  const handleCloseConversation = () => {
    setSelectedChannel(null);
  }

  if (!user || !process.env.REACT_APP_SENDBIRD_APP_ID) {
    return <div style={{ display: 'gap', padding: '48px 12px', wordBreak: "break-word" }}>
      <Typography.Title heading={5} style={{ wordBreak: "break-word" }}>We apologize, but something went wrong, and we couldn’t load your messages. </Typography.Title>

      <Typography.Paragraph style={{ wordBreak: "break-word" }}>Please try refreshing the page or check back later.</Typography.Paragraph>

      <Typography.Paragraph style={{ wordBreak: "break-word" }} >If the issue persists, please contact your administrator or technical support for assistance.</Typography.Paragraph>

      <Typography.Title heading={6} style={{ wordBreak: "break-word" }}> Thank you for your understanding!</Typography.Title>
    </div >
  }

  return (
    <div>
      {
        isMobile ? (
          <div style={{ height: '100%' }}>
            {selectedChannel ? (
              <Conversation
                activeConversation={selectedChannel}
                onCloseConversation={handleCloseConversation}
              />
            ) : (
              <ConversationsList
                onConversationSelected={setSelectedChannel}
                selectedConversation={selectedChannel}
              />
            )}
          </div>
        ) : (
          <div style={{ display: 'flex', height: '500px' }}>
            <ConversationsList onConversationSelected={setSelectedChannel} selectedConversation={selectedChannel} />
            <Conversation activeConversation={selectedChannel} onCloseConversation={handleCloseConversation} />
          </div>
        )
      }
    </div>
  );
};