import { Card, Image } from "@arco-design/web-react";
import axios, { AxiosResponse } from "axios";
import { endpoints } from "../api/endpoints";
import { useEffect, useState } from "react";
import { Banner as BannerType } from "../models/Banners";

type BannerProps = {
  size?: number;
  style?: React.CSSProperties;
};
export const Banner = (props: BannerProps) => {
  const [banner, setBanner] = useState<BannerType | null>(null);

  const getBanners = () => {
    axios
      .get(`${endpoints.getBanner}`)
      .then((response: AxiosResponse<BannerType[]>) => {
        if (response.data && response.data?.length > 0) {
          const published = response.data.find(banner => banner.status === 'published') || null;
          setBanner(published);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  };

  useEffect(() => {
    getBanners();
  }, []);

  if (!banner) {
    return null;
  }

  return (
    <Card className="card-with-icon-hover own-card">
      <Image
        width={props.size || "100%"}
        src={banner.url}
        alt="banner"
        style={props.style}
      />
    </Card>
  );
};
