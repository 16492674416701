import { Modal } from "@arco-design/web-react";
import './ConfirmationDialog.css';

type Props = {
  visible: boolean;
  text: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmationDialog({ visible, text, onConfirm, onCancel }: Props) {
  return (
    <Modal
      visible={visible}
      simple
      cancelText="No"
      okText="Yes"
      onCancel={onCancel}
      onOk={onConfirm}
      cancelButtonProps={{ className: "confirmation-dialog__button confirmation-dialog__button-secondary" }}
      okButtonProps={{ className: "confirmation-dialog__button" }}
      className="confirmation-dialog"
    >
      <div className="confirmation-dialog__text">{text}</div>
    </Modal>
  )
}