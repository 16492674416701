import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../models/Person";

interface AdminState {
  usersList: User[];
  selectedUser: User | null;
}

const initialState: AdminState = {
  usersList: [],
  selectedUser: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setUsersList: (state, action: PayloadAction<User[]>) => {
      state.usersList = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<User | null>) => {
      state.selectedUser = action.payload;
    },
  },
});

export const { setUsersList, setSelectedUser } = adminSlice.actions;
export default adminSlice.reducer;
