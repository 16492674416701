export const MAX_POST_LENGTH = 1000;
export const MAX_COMMENT_LENGTH = 1000;
export const MAX_TITLE_LENGTH = 140;

export const PREVIEW_NEEDED_LENGTH = 240;
export const SYMBOLS_COUNTER_NEEDED_LENGTH = {
  POST: 950,
  COMMENT: 950,
  TITLE: 90,
};
