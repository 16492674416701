import { Comment, Button, Avatar, Notification } from "@arco-design/web-react";
import { IconDelete } from "@arco-design/web-react/icon";
import { useMutation } from "@tanstack/react-query";

import ContentPreview from "../Post/ContentPreview";
import UpdateCommentModal from "../UpdateCommentModal";

import { CommentApi, PostApi } from "../../types/types";
import { updatePostRequest } from "../../../../../../api/feed";
import { getParsedArray } from "../../../../../../utils/getParsedArray";
import { formatDate } from "../../../../../../utils/formatDate";

interface ICommentProps {
  comment: CommentApi;
  post: PostApi;
  currentUserId: number;
  isMutating: boolean;
  isCurrentUserAdmin: boolean;
  setIsMutating: (value: boolean) => void;
  refetch: () => void;
  onUpdatePost: (postId: number, updatedPost: PostApi) => void;
}

const CommentComponent = ({
  comment,
  post,
  currentUserId,
  isCurrentUserAdmin,
  isMutating,
  setIsMutating,
  refetch,
  onUpdatePost,
}: ICommentProps) => {
  const { mutateAsync: deleteComment } = useMutation({
    mutationFn: updatePostRequest,
    onSuccess: () => {
      Notification.success({
        content: "Comment deleted successfully!",
      });
      setIsMutating(false);
      refetch();
    },
    onError: () => {
      Notification.error({
        content: "Error deleting post!",
      });
      setIsMutating(false);
    },
  });

  const handleDeleteComment = (post: PostApi, comment: CommentApi) => {
    setIsMutating(true);
    const likes = getParsedArray(post.likes);
    const comments = getParsedArray(post.comments);

    const updatedPost = {
      ...post,
      comments: comments.filter(
        (prevComment: CommentApi) =>
          prevComment.comment_id !== comment.comment_id
      ),
      likes: likes,
    };

    deleteComment([post.id, updatedPost]);
  };

  return (
    <Comment
      actions={
        comment.user_id === currentUserId || isCurrentUserAdmin
          ? [
              <Button
                className="list-demo-actions-icon"
                style={{
                  padding: 0,
                  backgroundColor: "transparent",
                }}
              >
                <UpdateCommentModal
                  comment={comment}
                  post={post}
                  isMutating={isMutating}
                  onUpdatePost={onUpdatePost}
                />
              </Button>,
              <Button
                className="list-demo-actions-icon"
                style={{
                  padding: 0,
                  backgroundColor: "transparent",
                }}
                disabled={isMutating}
              >
                <IconDelete
                  onClick={() => handleDeleteComment(post, comment)}
                />
              </Button>,
            ]
          : undefined
      }
      content={
        <div
          style={{
            display: "flex",
            gap: 8,
          }}
        >
          <div className="avatar-comment" style={{ width: 24, height: 24 }}>
            {comment.avatar_url ? (
              <Avatar shape="circle" size={24} style={{ marginRight: 10 }}>
                <img src={comment.avatar_url} alt={comment.user_name} />
              </Avatar>
            ) : (
              <Avatar shape="circle" size={24} style={{ marginRight: 10 }} />
            )}
          </div>
          <div
            style={{
              display: "grid",
              gap: 4,
              textAlign: "left",
            }}
          >
            <b>{comment.user_name}</b>
            <span
              style={{
                display: "flex",
                alignSelf: "center",
                fontSize: 10,
                fontWeight: 300,
              }}
            >
              {formatDate(comment.created_at)}
              {comment.created_at !== comment.edited_at &&
                ` | Edited - ${formatDate(comment.edited_at)}`}
            </span>
            <ContentPreview content={comment.content} />
          </div>
        </div>
      }
      className="sub-comment"
    />
  );
};

export default CommentComponent;
