import React, { useState } from "react";

import { PREVIEW_NEEDED_LENGTH } from "../../constants";

interface IContentPreviewProps {
  content: string;
}

const ContentPreview: React.FC<IContentPreviewProps> = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isContentPreviewNeeded = content.length > PREVIEW_NEEDED_LENGTH;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedContent = isExpanded
    ? content
    : `${content.slice(0, PREVIEW_NEEDED_LENGTH)}`;

  return (
    <p style={{ display: "inline-block", wordBreak: "break-word" }}>
      {displayedContent}
      {isContentPreviewNeeded && !isExpanded && "..."}
      {isContentPreviewNeeded && (
        <span
          style={{
            marginLeft: 8,
            textDecoration: "underline",
            cursor: "pointer",
            color: "#555",
          }}
          onClick={toggleExpand}
        >
          {isExpanded ? "See less" : "See more"}
        </span>
      )}
    </p>
  );
};

export default ContentPreview;
